import React, { useEffect, useMemo, useRef } from "react";
import {
    TutorialAnnotationContext,
    useFileExplorerStore,
    useToolbarContentItemIdStore,
    useToolbarMetricsStore,
    useToolbarTutorialStore,
    FloatingToolbar as EucFloatingToolbar,
} from "@amzn/aws-euc-ui";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { useFullScreenToolbarItem } from "../items/useFullScreenToolbarItem";
import { useFileToolbarItem } from "../items/useFileToolbarItem";
import { useClipboardToolbarItem } from "../items/useClipboardToolbarItem";
import { useDualMonitor } from "../items/useDualMonitor";
import { usePreferencesToolbarItem } from "../items/preference/usePreferencesToolbarItem";
import { useProfileToolbarItem } from "../items/user/useProfileToolbarItem";
import "../toolbar.css";
import { useFunctionKeysToolbarItem } from "../items/useFunctionKeysToolbarItem";
import { useTranslation } from "react-i18next";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import { useWindows } from "../items/useWindows";
import { useMicrophone } from "../items/useMicrophone";
import { MinimizedView } from "./MinimizedView";
import { useNotificationStore } from "../../../hooks/useNotificationStore";
import { useNotificationToolbarItem } from "../items/notification/useNotificationToolbarItem";
import { ToolbarItemId, ToolbarMode } from "../../../constants/Toolbar";
import { useWindowEventListener } from "@amzn/aws-euc-ui/dist/common/hooks/useWindowEventListener";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";
import {
    getTutorialCookie,
    setTutorialCookie,
    TutorialI18nStrings,
} from "../../../utils/tutorialUtils";
import { useTutorial } from "../items/useTutorial";
import { useSkipTutorialStore } from "../../../hooks/useSkipTutorialStore";
import { useApplications } from "../items/useApplications";
import { getApplicationFlag } from "../../../utils/toolbarItemUtils";
import { useCamera } from "../items/useCamera";
import { isFeatureEnabled } from "../../../configurations";
import { useMicrophoneWithDropdown } from "../items/useMicrophoneWithDropdown";
import { useCameraWithDropdown } from "../items/useCameraWithDropdown";

interface FloatingToolbarProps {
    appStreamSDK: AppStreamSDK;
    toolbarState?: ToolbarMode;
}

const isAppStreamEmbedIframeFocused = () =>
    document.activeElement.matches("iframe");

const useSetupFloatingToolbar = () => {
    const fileExplorerPreference = useFileExplorerStore(
        (store) => store.tablePreferences
    );
    useEffect(() => {
        fileExplorerPreference.wrapLines = true;
    }, []);

    const closeAllToolbarContent = useToolbarContentItemIdStore(
        (store) => store.clearExpandedItemId
    );

    /*
     * Emit a TOOLBAR_MOVED metric per connection when the toolbar is dragged by user at least once.
     */
    const toolbarMovementCount = useToolbarMetricsStore(
        (store) => store.movementCount
    );
    const hasToolbarMovement = toolbarMovementCount > 0;
    // Make sure we don't send toolbar movement metric multiple times.
    const publishToolbarMovementMetric = useRef(false);

    useEffect(() => {
        if (hasToolbarMovement && !publishToolbarMovementMetric.current) {
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.TOOLBAR_MOVED);
            publishToolbarMovementMetric.current = true;
        }
    }, [hasToolbarMovement]);

    const { setShowTutorial, setTutorialCompleted } = useToolbarTutorialStore();
    const isTutorialFinished = useMemo(() => getTutorialCookie(), []);
    useEffect(() => {
        if (isTutorialFinished) {
            setShowTutorial(false);
            setTutorialCompleted(true);
        }
    }, [isTutorialFinished]);

    const { isTutorialSkipped, setTutorialSkipped } = useSkipTutorialStore();
    useEffect(() => {
        if (isTutorialSkipped) {
            setShowTutorial(false);
            setTutorialCompleted(true);
            setTutorialCookie(true);
            setTutorialSkipped(false); // reset to false in case user relaunches the tutorial from help panel and want to skip again
        }
    }, [isTutorialSkipped]);

    /*
     * When a user clicks the iframe containing the AS2 streaming canvas,
     * a window which contains the toolbar will lose focus.
     */
    useWindowEventListener("blur", () => {
        // Chrome, Edge, and Safari
        if (isAppStreamEmbedIframeFocused()) {
            closeAllToolbarContent();
            return;
        }
        /*
         * Firefox will set the value of `document.activeElement` to
         * the `body`, instead of the actual focused element. To get
         * the correct focused element, we need to wait and check the
         * `document.activeElement`
         * https://developer.mozilla.org/en-US/docs/Web/API/Element/blur_event#browser_compatibility
         */
        setTimeout(() => {
            if (isAppStreamEmbedIframeFocused()) {
                closeAllToolbarContent();
                return;
            }
            // Check one more time after 300ms
            setTimeout(() => {
                if (isAppStreamEmbedIframeFocused()) {
                    closeAllToolbarContent();
                }
            }, 300);
        }, 300);
    });
};

/**
 * Filter out `undefined`, `null`, false, 0 items from the list
 */
const getConditionalArray = (...items) => items.filter((item) => !!item);

/**
 * Floating toolbar to allow users communicate with AppStream 2.0 SDK.
 */
export const FloatingToolbarInternal = ({
    appStreamSDK,
    toolbarState,
}: FloatingToolbarProps): React.JSX.Element => {
    const { t } = useTranslation();
    useSetupFloatingToolbar();

    const showFunctionKeysToolbarItem = useToolbarPreferenceStore(
        (store) => store.showFunctionKeysToolbarItem
    );
    const showApplicationToolbarItem = useMemo(() => getApplicationFlag(), []);

    const applicationToolbarItem = useApplications(appStreamSDK, toolbarState);
    const windowsToolbarItem = useWindows(appStreamSDK, toolbarState);
    const fileToolbarItem = useFileToolbarItem(appStreamSDK, toolbarState);
    const clipboardToolbarItem = useClipboardToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const dualMonitorToolbarItem = useDualMonitor(appStreamSDK);
    const fullScreenToolbarItem = useFullScreenToolbarItem(appStreamSDK);
    const microphoneToolbarItem = useMicrophone(appStreamSDK);
    const cameraToolbarItem = useCamera(appStreamSDK);
    const microphoneWithDropdownToolbarItem = useMicrophoneWithDropdown(
        appStreamSDK
    );
    const cameraWithDropdownButtonToolbarItem = useCameraWithDropdown(
        appStreamSDK
    );
    const functionKeysToolbarItem = useFunctionKeysToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const preferenceToolbarItem = usePreferencesToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const profileToolbarItem = useProfileToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const notificationItem = useNotificationToolbarItem(toolbarState);
    const expandedToolbarItem = useToolbarContentItemIdStore(
        (store) => store.expandedItemId
    );
    const floatingNotificationList = useNotificationStore(
        (state) => state.notificationList
    );
    // Don't show floating notifications if user expands the notification toolbar section (redundant).
    const conditionalNotificationList =
        expandedToolbarItem !== ToolbarItemId.NOTIFICATIONS
            ? floatingNotificationList
            : [];

    const isMediaDeviceSelectorFeatureEnabled = isFeatureEnabled(
        "mediaDeviceSelector"
    );
    const isDisableWebcamFeatureEnabled = isFeatureEnabled("disableWebcam");

    return (
        <>
            <EucFloatingToolbar
                defaultPosition={{
                    x: "center",
                    y: "top",
                }}
                i18nStrings={{
                    ariaLabelToolbar: t("toolbar.floating.ariaLabel"),
                    expandButtonAriaLabel: t(
                        "toolbar.floating.expand.button.ariaLabel"
                    ),
                    expandButtonTitle: t(
                        "toolbar.floating.expand.button.title"
                    ),
                    collapseButtonAriaLabel: t(
                        "toolbar.floating.collapse.button.ariaLabel"
                    ),
                    collapseButtonTitle: t(
                        "toolbar.floating.collapse.button.title"
                    ),
                }}
                items={getConditionalArray(
                    showApplicationToolbarItem && applicationToolbarItem,
                    windowsToolbarItem,
                    appStreamSDK.isFileExplorerEnabled() && fileToolbarItem,
                    appStreamSDK.isClipboardEnabled() && clipboardToolbarItem,
                    dualMonitorToolbarItem,
                    fullScreenToolbarItem,
                    !isMediaDeviceSelectorFeatureEnabled &&
                        microphoneToolbarItem,
                    isMediaDeviceSelectorFeatureEnabled &&
                        microphoneWithDropdownToolbarItem,
                    isMediaDeviceSelectorFeatureEnabled &&
                        !isDisableWebcamFeatureEnabled &&
                        cameraWithDropdownButtonToolbarItem,
                    showFunctionKeysToolbarItem && functionKeysToolbarItem,
                    preferenceToolbarItem,
                    notificationItem,
                    profileToolbarItem
                )}
                minimizedView={
                    <MinimizedView
                        items={getConditionalArray(
                            appStreamSDK.isFileExplorerEnabled() &&
                                fileToolbarItem,
                            microphoneToolbarItem,
                            isMediaDeviceSelectorFeatureEnabled &&
                                !isDisableWebcamFeatureEnabled &&
                                cameraToolbarItem,
                            notificationItem,
                            profileToolbarItem
                        )}
                    />
                }
                floatingNotificationListProps={{
                    stretch: true,
                    items: conditionalNotificationList,
                    ariaLabel: t("notification.list.ariaLabel"),
                }}
                withHotspot={true}
            />
            {/*
             * The draggable element wasn't able to catch up with the fast moving mouse cursor
             * if the draggable element is on top of iframe element. To avoid this issue, we need
             * to cover the screen with div element so that the draggable element is dragging on
             * top of a huge div element instead of the iframe
             * (https://github.com/react-grid-layout/react-draggable/issues/582)
             */}
            <div className="draggable-iframe-cover" />
        </>
    );
};

export const FloatingToolbar = ({
    appStreamSDK,
    toolbarState = ToolbarMode.Floating,
}: FloatingToolbarProps): React.JSX.Element => {
    const tutorialI18nStrings = TutorialI18nStrings();
    const currentTutorial = useTutorial(toolbarState);

    return (
        <TutorialAnnotationContext
            tutorialI18nStrings={tutorialI18nStrings}
            currentTutorial={currentTutorial}
            onFinish={() => setTutorialCookie(true)}
        >
            <FloatingToolbarInternal
                appStreamSDK={appStreamSDK}
                toolbarState={toolbarState}
            />
        </TutorialAnnotationContext>
    );
};
