// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import * as reactDeviceDetect from "react-device-detect";
import { UserAgentDeviceType } from "../enums/userAgentDeviceType";
import {
    NIVA_USER_AGENT_ID_BETA,
    NIVA_USER_AGENT_ID_GA,
    TEST_USER_AGENT_SUFFIX,
} from "../constants";
import { BrowserName, OsName } from "../types/UserAgent";
import { isFeatureEnabled } from "../configurations";

type ReactDevice = typeof ReactDeviceDetect;

let reactDevice: ReactDevice = reactDeviceDetect.getSelectorsByUserAgent(
    window.navigator.userAgent
) as ReactDevice;

function isNiva(): boolean {
    return (
        window.navigator.userAgent.includes(NIVA_USER_AGENT_ID_BETA) ||
        window.navigator.userAgent.includes(NIVA_USER_AGENT_ID_GA)
    );
}
// Extract device type from user-agent and based on model names categorize them into mobile,tablet.
export function getDeviceType(): UserAgentDeviceType {
    if (reactDevice?.isTablet) {
        return isNiva()
            ? UserAgentDeviceType.DESKTOP
            : UserAgentDeviceType.TABLET;
    } else if (reactDevice?.isMobile) {
        return UserAgentDeviceType.MOBILE;
    } else if (reactDevice?.isWearable) {
        return UserAgentDeviceType.WEARABLE;
    } else if (reactDevice?.isSmartTV) {
        return isNiva()
            ? UserAgentDeviceType.DESKTOP
            : UserAgentDeviceType.SMARTTV;
    } else {
        return UserAgentDeviceType.DESKTOP;
    }
}

export function getDeviceBrowser(): BrowserName {
    return reactDevice?.browserName as BrowserName;
}

export function getDeviceModel(): string {
    const deviceModel = reactDevice?.mobileModel;
    return deviceModel === (undefined || "none") ? "Unknown" : deviceModel;
}

export function getDeviceOSName(): OsName {
    return reactDevice?.osName as OsName;
}

export function isMacOS(): boolean {
    return getDeviceOSName() === "Mac OS";
}

export function getDeviceTouchInfo(): string {
    return window?.navigator?.maxTouchPoints > 1
        ? "Touchscreen"
        : "Non-touchscreen";
}

function isMobileDevice(deviceType: UserAgentDeviceType): boolean {
    return deviceType === UserAgentDeviceType.MOBILE;
}

function isTabletDevice(deviceType: UserAgentDeviceType): boolean {
    return deviceType === UserAgentDeviceType.TABLET;
}

export function isDeviceOnExperimentalMode(): boolean {
    const deviceType = getDeviceType();
    return isMobileDevice(deviceType) || isTabletDevice(deviceType);
}

export function isInternalTestTraffic(): boolean {
    return (
        window.navigator.userAgent != null &&
        window.navigator.userAgent.endsWith(TEST_USER_AGENT_SUFFIX)
    );
}

// function used from tests to reset the device load.
export function updateReactDeviceForTests(): void {
    reactDevice = reactDeviceDetect.getSelectorsByUserAgent(
        window.navigator.userAgent
    ) as ReactDevice;
}

/**
 * Checks if the browser is Chromium based or not. Based on:
 * - Known Chromium Browsers
 * - Rendering Engine used by Chromium Browsers (Blink)
 * - Match with all known non-Chromium Browsers
 */
export const isChromiumBasedBrowser = (): boolean => {
    return (
        reactDevice?.isChrome ||
        reactDevice?.isChromium ||
        reactDevice?.isEdgeChromium ||
        reactDevice?.isYandex ||
        reactDevice?.isOpera ||
        reactDevice?.isSamsungBrowser ||
        reactDevice?.engineName === "Blink"
    );
};

/**
 * Checks if WebCam is supported.
 * Right now, webcam is only supported on Chromium based Desktop browsers.
 * @returns
 */
export function isWebcamSupported(): boolean {
    return (reactDevice?.isDesktop || isNiva()) && isChromiumBasedBrowser();
}

export function isWebcamSupportedWithDocker(): boolean {
    return (
        reactDevice?.isDesktop &&
        isChromiumBasedBrowser() &&
        !isFeatureEnabled("disableWebcam") &&
        isFeatureEnabled("dockerizedChrome")
    );
}
