// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.

// This Amplify config is used only for local development.
// TODO(i/ERMINE-90): Delete me once ErmineService is internationalized and localized.

import { getCognitoBypassUrlOpener } from "../helpers";
import { AmplifyConfig } from "../configurations";
import localSetupJson from "../localTestSetup.json";

// To update these information, change the values in the `setup/localTestSetup.json` file
export const LOCALHOST_AMPLIFY_CONFIG: AmplifyConfig = {
    aws_cognito_identity_pool_id:
        localSetupJson["customer-config"].awsCognitoIdentityPoolId,
    aws_cognito_region: localSetupJson["customer-config"].awsCognitoRegion,
    aws_user_pools_id: localSetupJson["customer-config"].awsUserPoolsId,
    aws_user_pools_web_client_id:
        localSetupJson["customer-config"].awsUserPoolsWebClientId,
    oauth: {
        domain: localSetupJson["customer-config"].oAuth.domain,
        redirectSignIn: `${localSetupJson["customer-config"].oAuth.redirectSignIn[0]}sso`,
        redirectSignOut:
            localSetupJson["customer-config"].oAuth.redirectSignOut[0],
        scope: localSetupJson["customer-config"].oAuth.scope,
        responseType: localSetupJson["customer-config"].oAuth.responseType,
        urlOpener: getCognitoBypassUrlOpener("AmazonSSO"),
    },
    aws_cognito_endpoint: localSetupJson["customer-config"].isFipsEnabled
        ? localSetupJson.awsCognitoEndpoint
        : undefined,
};
