import { StreamingURLCache } from "../../../../types/sessionState";
import { sessionStateCookieManager } from "../../../../utils/SessionStateCookieManager";
import log from "../../../../logging";
import { Buffer } from "buffer";

/**
 * Buffer in seconds to a URL expiration for a streamingURL from cache.
 * If the cached URL has expiration time closer than this buffer, it's deemed
 * invalid.
 */
const URL_EXPIRATION_BUFFER_SECONDS = 15;

/**
 * Extracts the expiration Epoch from the streaming URL parameter.
 * @param params from streaming URL query
 * @returns expiration epoch
 * @throws SyntaxError if provided params are not a valid base64 encoded JSON
 * @throws RangeError if expires is undefined
 */
const parseStreamingExpirationEpoch = (params: string): number => {
    const decodedParameters = Buffer.from(params, "base64").toString("utf8");
    const jsonParams = JSON.parse(decodedParameters);
    if (jsonParams.expires === undefined) {
        throw new RangeError("Invalid Expiration timestamp.");
    }
    return jsonParams.expires;
};

/**
 * This function parses the provided streaming URL and checks if its still valid
 * to be reused or not.
 *
 * It depends on implementation details from AppStream2's Streaming URL.
 * {"type":"END_USER","expires":"1685399881","awsAccountId":"539164923896","userId":"f1dfd5f6d2004923bc2aae5f307ff173","catalogSource":"stack/stack-1003c3ea-82b7-46cc-b0b3-dffd9c25c754","fleetRef":"fleet/fleet-1003c3ea-82b7-46cc-b0b3-dffd9c25c754","applicationId":"chrome","userContext":"{\"userLoggingEnabled\":true,\"stage\":\"beta\",\"region\":\"us-west-2\",\"proxy\":\"http://823a6641-e17e-4dfc-af20-33bfb30c0a24.appstream.local:3128\",\"bucket\":\"2bpdxlemjson\",\"object\":\"T8d+pyZHQPKFjwzLPN+lXg==\",\"envelopeEncryptionKey\":\"arn:aws:kms:us-west-2:539164923896:key/f9528255-2529-4097-874c-7c9f4ac0b212\",\"encryptionBridgeRole\":\"arn:aws:iam::662269042345:role/EncryptionBridgeRole\",\"isFipsEnabled\":false,\"language\":\"en-US\",\"timeZone\":\"America/Los_Angeles\"}","maxUserDurationInSecs":"36000"}
 *
 * Checks the `expires` timestamp in the URL to know if the streaming URL is
 * valid or not.
 * @param streamingURL to be checked
 * @returns true if valid, false otherwise
 */
const isValidStreamingURL = (streamingURL: string): boolean => {
    const currentEpochSeconds = new Date().getTime() / 1000;
    try {
        const url = new URL(streamingURL);
        const urlExpirationEpochSeconds = parseStreamingExpirationEpoch(
            url.searchParams.get("parameters")
        );
        return (
            currentEpochSeconds + URL_EXPIRATION_BUFFER_SECONDS <=
            urlExpirationEpochSeconds
        );
    } catch (e) {
        // Note: we are intentionally not logging any streaming URLs here to prevent
        // any kind of information leak.
        if (e instanceof SyntaxError) {
            log.warn("Invalid StreamingURL: Invalid base64 encoded parameters");
        } else if (e instanceof RangeError) {
            log.warn("Invalid StreamingURL: Expiration time not found");
        } else {
            log.warn("Unexpected Error occurred", e);
        }
        return false;
    }
};

/**
 * Refreshes the streaming URL into Cache provided valid streaming URL was
 * provided. Any previously cached streaming URL will be overwritten.
 * @param streamingURL to be cached
 * @param browserType browser type to be cached
 * @returns true if succeeds, false otherwise
 */
export const refreshStreamingURLCache = (
    streamingURL: string,
    browserType: string
): boolean => {
    if (isValidStreamingURL(streamingURL) === true) {
        const streamingURLCache: StreamingURLCache = {
            streamingURL: streamingURL,
            browserType: browserType,
        };
        return sessionStateCookieManager.setStreamingURLCache(
            streamingURLCache
        );
    }
    return false;
};

/**
 * Force flushes Cache. Throws an Error if it is unable to flush the
 * backing session storage.
 *
 * @throws Error when session storage write-back fails
 */
export const flushStreamingURLCache = () => {
    const emptyCache: StreamingURLCache = {};
    const wasFlushed: boolean = sessionStateCookieManager.setStreamingURLCache(
        emptyCache
    );
    if (wasFlushed === false) {
        throw new Error("Could not write to sessionStorage.");
    }
};

/**
 * Gets a valid streaming URL from cache if present. Checks for expiration
 * of any URL in cache before it returns it.
 * @returns valid streaming URL & Browser type if present, undefined otherwise
 */
export const fetchStreamingURLCache = ():
    | { streamingURL: string; browserType: string }
    | undefined => {
    const streamingURLCache: StreamingURLCache = sessionStateCookieManager.getStreamingURLCache();
    if (
        streamingURLCache !== undefined &&
        streamingURLCache.streamingURL !== undefined &&
        streamingURLCache.browserType !== undefined &&
        isValidStreamingURL(streamingURLCache.streamingURL)
    ) {
        log.info("Streaming URL cache hit!");
        return {
            streamingURL: streamingURLCache.streamingURL,
            browserType: streamingURLCache.browserType,
        };
    }
    log.info("Streaming URL cache miss!");
    return undefined;
};
