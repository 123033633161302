import { AppStreamSDK } from "./AppStreamSDK";
import ErmineServerSideRenderingService, {
    ClientMessage,
    SendClientMessageRequest,
    SendClientMessageResponse,
} from "@amzn/ermine-ssr-sdk/clients/ermineserversiderenderingservice";
import log, { METRIC_NAME } from "../logging";
import { CLIENT_MESSAGE_TYPE } from "../constants/clientMessage";
import {
    DEFAULT_CLIENT_CONFIG,
    getEnvInfo,
    getPortalConfig,
} from "../configurations";
import { AWSError } from "aws-sdk/lib/error";
import { ApplicationName } from "../hooks/useAppStreamApplications";

export function sendClientMessage(
    appStreamSdk: AppStreamSDK,
    deepLinks,
    onErrorCallback
): void {
    const message = buildClientMessage(deepLinks);
    if (message == null) {
        return;
    }

    const { stage, region } = getEnvInfo();
    const ermineService = new ErmineServerSideRenderingService({
        ...DEFAULT_CLIENT_CONFIG,
        maxRetries: 2,
        region,
        endpoint: `${window.location.origin}`,
    });
    ermineService.makeUnauthenticatedRequest(
        "sendClientMessage",
        {
            portalArn: getPortalConfig().portalArn,
            message,
        } as SendClientMessageRequest,
        async (err: AWSError, data: SendClientMessageResponse) => {
            if (err) {
                onErrorCallback(
                    err.statusCode,
                    message.openDeepLinks.deepLinks
                );
                log.error(
                    `SendClientMessage ${err.statusCode} | ${err.name} | ${err.code}: ${err.message}`
                );
                log.publishHttpStatusMetric(
                    METRIC_NAME.SEND_CLIENT_MESSAGE,
                    err.statusCode
                );
            } else {
                log.info(`Client message sent with ID: ${data?.messageId}`);
                log.publishHttpStatusMetric(
                    METRIC_NAME.SEND_CLIENT_MESSAGE,
                    200
                );
                let appName;
                if (stage === "prod") {
                    appName = "ClientMessageHandler";
                } else {
                    // App name contains developer alias in preprod if custom Appblock is used.
                    // Note this call adds around 500ms to e2e latency for features replies on it like deep
                    // link.
                    appName = await appStreamSdk
                        .getApplications()
                        .then(
                            (applications) =>
                                applications.find(
                                    (app) =>
                                        app.displayName ===
                                        ApplicationName.CLIENT_MESSAGE_HANDLER
                                )?.name
                        );
                }
                appStreamSdk.launchApp(appName);
            }
        }
    );
}

export function buildClientMessage(deepLinks): ClientMessage {
    if (!Array.isArray(deepLinks)) {
        log.error("Invalid type. Deep links is not an array");
        return;
    }

    if (!deepLinks?.length) {
        return;
    }

    return {
        type: CLIENT_MESSAGE_TYPE.OPEN_DEEP_LINKS,
        openDeepLinks: {
            deepLinks,
        },
    };
}
