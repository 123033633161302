// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { useEffect } from "react";
import browser from "webextension-polyfill";
import {
    DEFAULT_CLIENT_CONFIG,
    getEnvInfo,
    getPortalConfig,
} from "../../../../configurations";
import { AWSError } from "aws-sdk";
import log, { METRIC_NAME } from "../../../../logging";
import {
    WorkSpacesWeb as ErmineControlPlaneServiceInternal,
    getEndpoint,
} from "@amzn/awserminecontrolplaneserviceinternal";
import { ICredentials } from "@aws-amplify/core";

interface Props {
    onCookieSyncConfigFetch: (
        allowlistFilter: browser.Cookies.GetAllDetailsType[],
        blocklistFilter: browser.Cookies.GetAllDetailsType[],
        metricName: METRIC_NAME
    ) => void;
    onCookieSyncConfigFetchError: (
        err: AWSError,
        metricName: METRIC_NAME
    ) => void;
    sigv4Credentials: ICredentials;
}

const Sigv4CookieSyncConfigFetcher = (props: Props): JSX.Element | null => {
    const {
        onCookieSyncConfigFetch,
        onCookieSyncConfigFetchError,
        sigv4Credentials,
    } = props;
    const portalConfig = getPortalConfig();
    const portalArn = portalConfig.portalArn;
    const { stage, region } = getEnvInfo();
    const serviceEndpoint = getEndpoint(
        stage,
        region,
        portalConfig.isFipsEnabled
    );
    useEffect(() => {
        const ermineService = new ErmineControlPlaneServiceInternal({
            ...DEFAULT_CLIENT_CONFIG,
            region: region,
            credentials: {
                accessKeyId: sigv4Credentials.accessKeyId,
                secretAccessKey: sigv4Credentials.secretAccessKey,
                sessionToken: sigv4Credentials.sessionToken,
            },
            endpoint: serviceEndpoint,
        });

        ermineService.getCookieSynchronizationConfiguration(
            {
                portalArn,
            },
            (err: AWSError, data) => {
                if (err) {
                    log.error(
                        `Sigv4CookieSyncConfigFetcher ${err.statusCode} | ${err.code}: ${err.message}`
                    );
                    onCookieSyncConfigFetchError(
                        err,
                        METRIC_NAME.SIGV4_GET_COOKIE_SYNC_CONFIG
                    );
                } else {
                    const { cookieSynchronizationConfiguration } = data;
                    onCookieSyncConfigFetch(
                        cookieSynchronizationConfiguration?.allowlist,
                        cookieSynchronizationConfiguration?.blocklist,
                        METRIC_NAME.SIGV4_GET_COOKIE_SYNC_CONFIG
                    );
                }
            }
        );
    }, [
        onCookieSyncConfigFetch,
        onCookieSyncConfigFetchError,
        portalArn,
        region,
        serviceEndpoint,
        sigv4Credentials.accessKeyId,
        sigv4Credentials.secretAccessKey,
        sigv4Credentials.sessionToken,
    ]);

    return null;
};

export default Sigv4CookieSyncConfigFetcher;
