import {
    ToolbarItemId,
    ToolbarPreferenceTabId,
    ToolbarProfileTabId,
} from "../../constants/Toolbar";
import { Link } from "@cloudscape-design/components";
import React from "react";
import { useToolbarContentItemIdStore } from "@amzn/aws-euc-ui";
import { useToolbarPreferenceTabIdStore } from "../../hooks/useToolbarPreferenceTabIdStore";
import { useToolbarProfileTabIdStore } from "../../hooks/useToolbarProfileTabIdStore";

interface LinkToPreferenceProps {
    tabId: ToolbarPreferenceTabId;
    children?: React.ReactNode;
}

/**
 * Link component to show the target setting form field in the Preference toolbar item.
 */
export const LinkToPreference = (props: LinkToPreferenceProps) => {
    const openToolbarItem = useToolbarContentItemIdStore(
        (store) => store.setExpandedItemId
    );
    const openPreferenceTab = useToolbarPreferenceTabIdStore(
        (store) => store.setActiveId
    );

    return (
        <Link
            onFollow={() => {
                openToolbarItem(ToolbarItemId.PREFERENCES);
                openPreferenceTab(props.tabId);
            }}
        >
            {props.children}
        </Link>
    );
};

interface LinkToProfileProps {
    tabId: ToolbarProfileTabId;
    children?: React.ReactNode;
}

/**
 * Link component to show the target tab in the Profile toolbar item.
 */
export const LinkToProfile = (props: LinkToProfileProps) => {
    const openToolbarItem = useToolbarContentItemIdStore(
        (store) => store.setExpandedItemId
    );
    const openProfileTab = useToolbarProfileTabIdStore(
        (store) => store.setActiveId
    );

    return (
        <Link
            onFollow={() => {
                openToolbarItem(ToolbarItemId.PROFILE);
                openProfileTab(props.tabId);
            }}
        >
            {props.children}
        </Link>
    );
};
