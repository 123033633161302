import create from "zustand";

interface UseMediaDeviceStoreState {
    selectedMicrophone: { label: string; deviceId: string };
    setSelectedMicrophone: (value: { label: string; deviceId: string }) => void;
    selectedWebcam: { label: string; deviceId: string };
    setSelectedWebcam: (value: { label: string; deviceId: string }) => void;
    webcams: Array<{ label: string; deviceId: string }>;
    microphones: Array<{ label: string; deviceId: string }>;
    setMicrophones: (value: Array<{ label: string; deviceId: string }>) => void;
    setWebcams: (value: Array<{ label: string; deviceId: string }>) => void;
    isMicrophonePermissionAllowed: boolean;
    setIsMicrophonePermissionAllowed: (value: boolean) => void;
    isWebcamPermissionAllowed: boolean;
    setIsWebcamPermissionAllowed: (value: boolean) => void;
}

/**
 * Store for managing the media devices values.
 */
export const useMediaDeviceStore = create<UseMediaDeviceStoreState>((set) => ({
    selectedMicrophone: undefined,
    setSelectedMicrophone: (value) =>
        set((state) => ({
            ...state,
            selectedMicrophone: {
                label: value.label,
                deviceId: value.deviceId,
            },
        })),
    selectedWebcam: undefined,
    setSelectedWebcam: (value) =>
        set((state) => ({
            ...state,
            selectedWebcam: { label: value.label, deviceId: value.deviceId },
        })),
    webcams: [],
    setWebcams: (value) =>
        set((state) => ({
            ...state,
            webcams: value,
        })),
    microphones: [],
    setMicrophones: (value) =>
        set((state) => ({
            ...state,
            microphones: value,
        })),
    isMicrophonePermissionAllowed: false,
    setIsMicrophonePermissionAllowed: (value) =>
        set((state) => ({
            ...state,
            isMicrophonePermissionAllowed: value,
        })),
    isWebcamPermissionAllowed: false,
    setIsWebcamPermissionAllowed: (value) =>
        set((state) => ({
            ...state,
            isWebcamPermissionAllowed: value,
        })),
}));
