// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { WORKSPACES_WEB, CONFIG_ELEMENTS } from "../constants";
import { ErmineLogger } from "./ermineLogger";

let logger: ErmineLogger;

// Katal uses SendBeacon API to send messages
// Max message size for SendBeacon is determined by userAgent being used. For Chrome max size is 65536
// https://stackoverflow.com/questions/28989640/navigator-sendbeacon-data-size-limits
const BEACON_LOG_CHAR_SIZE = 65536;
const LOG_MAX_SAFETY_PADDING = 0.9;
const MAX_LOG_LINE_SIZE = Math.floor(
    BEACON_LOG_CHAR_SIZE * LOG_MAX_SAFETY_PADDING
);

export const initalizeLogger = (): ErmineLogger => {
    const environmentConfigElement = document.getElementById(
        CONFIG_ELEMENTS.ENV_INFO
    );
    const environmentConfig = environmentConfigElement
        ? JSON.parse(environmentConfigElement.innerHTML)
        : {};
    const {
        stage = WORKSPACES_WEB.STAGE,
        loggerEndpoint = null,
    } = environmentConfig;

    logger = new ErmineLogger({
        url: loggerEndpoint,
        logToConsole: stage !== "prod",
        // https://sage.amazon.dev/posts/1371557?t=7
        maxLogLineSize: MAX_LOG_LINE_SIZE,
    });

    logger.addErrorListener();

    return logger;
};

export default logger || initalizeLogger();
export * from "./metricName";
