import i18n from "i18next";
import { initReactI18next, TFunction } from "react-i18next";

// TODO Load language messages dynamically (LOWA-2404)
import deMessages from "@amzn/aws-ermine-webapp-localization/locales/de/de-DE.properties";
import enMessages from "@amzn/aws-ermine-webapp-localization/locales/en/en-US.properties";
import esMessages from "@amzn/aws-ermine-webapp-localization/locales/es/es-ES.properties";
import frMessages from "@amzn/aws-ermine-webapp-localization/locales/fr/fr-FR.properties";
import idMessages from "@amzn/aws-ermine-webapp-localization/locales/id/id-ID.properties";
import itMessages from "@amzn/aws-ermine-webapp-localization/locales/it/it-IT.properties";
import jaMessages from "@amzn/aws-ermine-webapp-localization/locales/ja/ja-JP.properties";
import koMessages from "@amzn/aws-ermine-webapp-localization/locales/ko/ko-KR.properties";
import ptMessages from "@amzn/aws-ermine-webapp-localization/locales/pt-BR/pt-BR.properties";
import zhCnMessages from "@amzn/aws-ermine-webapp-localization/locales/zh-CN/zh-CN.properties";
import zhTwMessages from "@amzn/aws-ermine-webapp-localization/locales/zh-TW/zh-TW.properties";
import { getBrowserLanguage } from "./utils/localizationUtils";
import { isFeatureEnabled } from "./configurations";

const TRADITIONAL_CHINESE_CODE_REGEX = /zh[-_]tw/i;
const CHINESE_LANGUAGE_CODE = "zh";
export enum Language {
    German = "de-DE",
    English = "en-US",
    Spanish = "es-ES",
    French = "fr-FR",
    Indonesian = "id-ID",
    Italian = "it-IT",
    Japanese = "ja-JP",
    Korean = "ko-KR",
    Portuguese = "pt-BR",
    SimplifiedChinese = "zh-CN",
    TraditionalChinese = "zh-TW",
}
export const LANGUAGE_DISPLAY_NAMES = {
    [Language.German]: "Deutsch",
    [Language.English]: "English",
    [Language.Spanish]: "Español",
    [Language.French]: "Français",
    [Language.Indonesian]: "Bahasa Indonesia",
    [Language.Italian]: "Italiano",
    [Language.Japanese]: "日本語",
    [Language.Korean]: "한국어",
    [Language.Portuguese]: "Português (Brasil)",
    [Language.SimplifiedChinese]: "中文(简体)",
    [Language.TraditionalChinese]: "中文(繁體)",
};
// use English as fallback language
export const DEFAULT_FALLBACK_LANGUAGE = Language.English;

const supportedLanguages = Object.values(Language);
// translations
const resources = {
    [Language.German]: {
        translation: deMessages,
    },
    [Language.English]: {
        translation: enMessages,
    },
    [Language.Spanish]: {
        translation: esMessages,
    },
    [Language.French]: {
        translation: frMessages,
    },
    [Language.Indonesian]: {
        translation: idMessages,
    },
    [Language.Italian]: {
        translation: itMessages,
    },
    [Language.Japanese]: {
        translation: jaMessages,
    },
    [Language.Korean]: {
        translation: koMessages,
    },
    [Language.Portuguese]: {
        translation: ptMessages,
    },
    [Language.SimplifiedChinese]: {
        translation: zhCnMessages,
    },
    [Language.TraditionalChinese]: {
        translation: zhTwMessages,
    },
};

function getLanguageCode(code: string) {
    return code.split(/[-_]/)[0];
}

/**
 * Get target language based on the user's local browser language settings
 *
 * Export this function to test it
 */
export function getTargetLanguage(currentBrowserLanguage: string): Language {
    if (!currentBrowserLanguage) {
        // use English as fallback language
        return DEFAULT_FALLBACK_LANGUAGE;
    }

    // Traditional Chinese (zh-TW) is the special case
    // For others, we will check the prefix langauge code only
    // This follows https://aws.amazon.com/ language setting
    if (TRADITIONAL_CHINESE_CODE_REGEX.test(currentBrowserLanguage)) {
        return Language.TraditionalChinese;
    }

    // For Simplified Chinese (zh-CN)
    if (getLanguageCode(currentBrowserLanguage) === CHINESE_LANGUAGE_CODE) {
        return Language.SimplifiedChinese;
    }
    // For other languages
    const matchLanguageCodeOnly = Object.values(Language).find(
        (value) =>
            getLanguageCode(currentBrowserLanguage) === getLanguageCode(value)
    );
    if (matchLanguageCodeOnly) {
        return matchLanguageCodeOnly;
    }

    // use English as fallback language
    return Language.English;
}
const currentBrowserLanguage = getBrowserLanguage();
const targetLanguage = getTargetLanguage(currentBrowserLanguage);

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: targetLanguage,
        fallbackLng: Language.English,
        load: "currentOnly", // To avoid warnings. (e.g. rejecting language code not found in supportedLngs: en)
        supportedLngs: supportedLanguages,
        debug: true,
        keySeparator: false,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

export function getRebrandedMessageId(messageId: string): string {
    return `${messageId}Rebranded`;
}

export function hasRebrandedMessageId(messageId: string): boolean {
    return (
        isFeatureEnabled("rebrand") &&
        i18n.exists(getRebrandedMessageId(messageId))
    );
}

export function getRebrandedMessageIdIfExists(messageId: string): string {
    if (hasRebrandedMessageId(messageId)) {
        return getRebrandedMessageId(messageId);
    }
    return messageId;
}

export function getRebrandedTranslation(
    id: string,
    translationFunction: TFunction
): string {
    const messageId: string = getRebrandedMessageIdIfExists(id);
    return translationFunction(messageId);
}
