// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import log, { METRIC_NAME, TOOLBAR_METRIC_NAME } from "../../../../logging";
import { AuthState } from "../../../../types";
import { AuthStatus } from "../../../../enums";
import { AppStreamSDK } from "../../../../utils/AppStreamSDK";
import { FloatingToolbar } from "../../../toolbar/FloatingToolbar/FloatingToolbar";
import { SessionErrorType } from "../../../../types/appStream";
import { AppStreamEmbedConstant } from "../../../../constants";
import {
    SESSION_STATE_STARTED_TIMEOUT,
    ToolbarMode,
} from "../../../../constants/Toolbar";

import { applyMode, Mode } from "@cloudscape-design/global-styles";
import "./embedStreamer.css";
import { useToolbarPreferenceStore } from "../../../../hooks/useToolbarPreferenceStore";
import {
    getDirectImportFlag,
    getLegacyFlag,
    getMobileDeviceFlag,
} from "../../../../utils/toolbarItemUtils";

import { useSessionStatusListener } from "../../../../hooks/useSessionStatusListener";
import {
    convertErrorTypeFromEmbedEvent,
    getToolbarVersionFromUserInterface,
} from "../../../../utils/appstreamEventUtils";
import { ConditionalRenderErrorModal } from "../../../presession/ErrorModal";
import classNames from "classnames";
import Loader from "../../../Loader";
import { isDeviceOnExperimentalMode } from "../../../../utils/userAgentUtils";
import { useSessionDataStore } from "../../../../hooks/useSessionDataStore";
import useClipboardPermissions from "../../../../hooks/useClipboardPermissions";
import { DockedToolbar } from "../../../toolbar/DockedToolbar/DockedToolbar";
import { AppStreamEmbed, EmbedConstants } from "@amzn/photon-portal-embed-sdk";
import { isFeatureEnabled } from "../../../../configurations";
import { useMicrophoneTimer } from "../../../../hooks/useMicrophoneTimer";
import { useFloatingToolbarModeTimer } from "../../../../hooks/useFloatingToolbarModeTimer";
import { useEmitSessionMetrics } from "../../../../hooks/useEmitSessionMetrics";
import { useToolbarIconLabelStore } from "@amzn/aws-euc-ui/dist/toolbar/hooks/useToolbarIconLabelStore";
import { getToolbarIconLabelsEnabledFromCookie } from "../../../../utils/toolbarSettingsUtils";
import { useTranslation } from "react-i18next";

const APPSTREAM_DIV_ID = "appstream-container";

interface Props {
    endpoint: string;
    onSignOut: (authState: AuthState) => void;
    onRedirectToLogin: (redirectToLogin: boolean) => void;
}

export const EmbeddedStreamer = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    // TODO Short term solution for showing default docked toolbar experience for specific portals (https://sim.amazon.com/issues/LOWA-15355)
    const isDefaultDockedToolbar = isFeatureEnabled(
        "defaultDockedToolbarExperience"
    );
    // TODO For mobile users, present the legacy experience until we can resolve the on-screen keyboard issue. (https://issues.amazon.com/issues/LOWA-11655)
    const isLegacy = useMemo(
        () => getLegacyFlag() || isDeviceOnExperimentalMode(),
        []
    );
    const isMobileFlagOn = useMemo(() => getMobileDeviceFlag(), []);
    const visualMode = useToolbarPreferenceStore((store) => store.visualMode);
    useEffect(() => {
        applyMode(visualMode);
    }, [visualMode]);

    const toolbarMode = useToolbarPreferenceStore((store) => store.toolbarMode);
    const isFloatingToolbar = toolbarMode === ToolbarMode.Floating;
    const isDockedToolbar = toolbarMode === ToolbarMode.Docked;

    const setToolbarMode = useToolbarPreferenceStore(
        (store) => store.setToolbarMode
    );

    useEffect(() => {
        if (isDefaultDockedToolbar || isMobileFlagOn) {
            setToolbarMode(ToolbarMode.Docked);
        } else {
            setToolbarMode(toolbarMode);
        }
    }, [toolbarMode]);

    const microphoneEnabled = useToolbarPreferenceStore(
        (store) => store.microphoneEnabled
    );

    useMicrophoneTimer(microphoneEnabled);
    useFloatingToolbarModeTimer(toolbarMode);
    useEmitSessionMetrics();

    const { endpoint, onSignOut, onRedirectToLogin } = props;
    const isUserDisconnected = useRef(false);
    const [isAppStreamIframeRendered, setIsAppStreamIframeRendered] = useState(
        false
    );
    const [sessionErrorType, setSessionErrorType] = useState<SessionErrorType>(
        "None"
    );

    const [appStreamSDK, setAppStreamSDK] = useState<AppStreamSDK>();

    const [spinnerStatus, setSpinnerStatus] = useState({
        spinnerVisible: true,
        message: t("euc.loading.screen.connecting.message"), // Set the default loading message for the initial state. This will minimize the loading screen changes.
    });

    const userEndSession = useSessionDataStore((store) => store.userEndSession);

    useEffect(() => {
        if (!isAppStreamIframeRendered || !endpoint) {
            return;
        }

        // TODO For mobile users, present the legacy experience until we can resolve the on-screen keyboard issue. (https://issues.amazon.com/issues/LOWA-11655)
        const isLegacy = getLegacyFlag() || isDeviceOnExperimentalMode();
        const isMobileFlagOn = getMobileDeviceFlag();

        const hiddenElements =
            isLegacy && !isMobileFlagOn
                ? undefined
                : [
                      AppStreamEmbedConstant.ELEMENT_TOOLBAR_MAIN,
                      AppStreamEmbedConstant.ELEMENT_ALERT,
                  ];
        const appstreamOptions = {
            sessionURL: endpoint,
            userInterfaceConfig: {
                [AppStreamEmbedConstant.OPTION_HIDDEN_ELEMENTS]: hiddenElements,
            },
        };

        const sessionStartedNotReceivedCallback = () => {
            log.warn(
                "AS2 embed SESSION_STATE_CHANGE Started event not received after: " +
                    SESSION_STATE_STARTED_TIMEOUT +
                    " sec"
            );
        };
        const sessionStartedNotReceivedTimer = setTimeout(
            sessionStartedNotReceivedCallback,
            SESSION_STATE_STARTED_TIMEOUT * 1000
        );

        const embedInitialLoadStart = performance.now();
        const updateSessionStateCallback = (event) => {
            log.logMessage(
                "AS2 embed SESSION_STATE_CHANGE event: " + JSON.stringify(event)
            );
            if (
                event.sessionStatus ===
                EmbedConstants.SessionStatus[
                    EmbedConstants.SessionStatus.Started
                ]
            ) {
                // Publish how long it takes for AppStream to return its first frame status.
                log.publishNumericMetric(
                    TOOLBAR_METRIC_NAME.SESSION_STARTED_RESPONSE_TIME,
                    performance.now() - embedInitialLoadStart
                );
                clearTimeout(sessionStartedNotReceivedTimer);
            }
            // Sign out when the session is ended.
            // See event detail in https://tiny.amazon.com/nppg2ccj/docsawsamazappslatedeveembe.
            if (
                event.sessionStatus ===
                EmbedConstants.SessionStatus[EmbedConstants.SessionStatus.Ended]
            ) {
                log.logMessage("User ended the session. Signing out.");
                onSignOut({ authStatus: AuthStatus.SIGNING_OUT });
            }
            if (
                event.sessionStatus ===
                EmbedConstants.SessionStatus[
                    EmbedConstants.SessionStatus.Disconnected
                ]
            ) {
                log.logMessage("User disconnected the session.");
                isUserDisconnected.current = true;
                if (!userEndSession) {
                    // If a user didn't end a session but the session is disconnected, it could mean that
                    // 1. Admin ends the session
                    // 2. In-session script throws some error
                    // 3. AS2 expires the session
                    // We need to figure out a way to differentiate these cases and show appropriate message.
                    // Until then, we will show disconnect warning message and hide AS2 page in an iframe.
                    // Ticket: https://issues.amazon.com/issues/LOWA-22575
                    setSessionErrorType("DisconnectWarning");
                }
            }
        };

        const updateUserInterfaceStateCallback = (event) => {
            log.logMessage(
                "AS2 embed SESSION_INTERFACE_STATE_CHANGE event: " +
                    JSON.stringify(event)
            );
            const toolbarVersion = getToolbarVersionFromUserInterface(event);
            if (toolbarVersion !== null) {
                log.publishMetricWithDimension(
                    TOOLBAR_METRIC_NAME.TOOLBAR_VERSION,
                    toolbarVersion
                );
            }
        };

        const errorCallback = (event) => {
            log.logMessage(
                "AS2 embed SESSION_ERROR event: " + JSON.stringify(event)
            );
            const errorType = convertErrorTypeFromEmbedEvent(event);
            setSessionErrorType(errorType);
            // There's an expected 500 error when user disconnects from session and session expires
            // See ticket: https://t.corp.amazon.com/P81729928
            if (event.errorCode === 500 && !isUserDisconnected.current) {
                log.publishCounterMetric(
                    METRIC_NAME.APPSTREAM_EMBED_SERVER_ERROR
                );
            }
        };

        const updateSpinnerCallback = (event) => {
            log.logMessage(
                "AS2 embed SESSION_SPINNER event: " + JSON.stringify(event)
            );
            setSpinnerStatus(event);
        };

        const appStreamEmbed = new AppStreamEmbed(
            APPSTREAM_DIV_ID,
            appstreamOptions
        );

        appStreamEmbed.addEventListener(
            EmbedConstants.Events.EVENT_SESSION_STATE_CHANGED,
            updateSessionStateCallback
        );
        appStreamEmbed.addEventListener(
            EmbedConstants.Events.EVENT_USER_INTERFACE_STATE_CHANGED,
            updateUserInterfaceStateCallback
        );
        appStreamEmbed.addEventListener(
            EmbedConstants.Events.EVENT_SESSION_ERROR,
            errorCallback
        );

        appStreamEmbed.addEventListener(
            EmbedConstants.Events.EVENT_SPINNER,
            updateSpinnerCallback
        );

        if (!isLegacy || isMobileFlagOn) {
            setAppStreamSDK(new AppStreamSDK(appStreamEmbed));
        }
        return () => {
            clearTimeout(sessionStartedNotReceivedTimer);
        };
    }, [isAppStreamIframeRendered, endpoint, onSignOut]);

    useSessionStatusListener(appStreamSDK);
    const sessionState = useSessionDataStore((store) => store.sessionStatus);
    const showErrorModal =
        (!isLegacy || isMobileFlagOn) &&
        sessionErrorType !== "None" &&
        sessionState !== "Started";

    useClipboardPermissions();

    const showSpinner =
        (!isLegacy || isMobileFlagOn) && spinnerStatus.spinnerVisible;
    const showFloatingToolbar =
        (!isLegacy || isMobileFlagOn) &&
        sessionState === "Started" &&
        !showSpinner &&
        isFloatingToolbar;
    const showDockedToolbar = (!isLegacy || isMobileFlagOn) && isDockedToolbar;
    const setToolbarIconLabelsEnabled = useToolbarIconLabelStore(
        (store) => store.setToolbarIconLabelsEnabled
    );
    useEffect(() => {
        if (getToolbarIconLabelsEnabledFromCookie() !== undefined) {
            setToolbarIconLabelsEnabled(
                getToolbarIconLabelsEnabledFromCookie()
            );
        }
    }, []);

    return (
        <div
            className={classNames(
                "embed-wrapper",
                visualMode === Mode.Light
                    ? "light-visual-mode"
                    : "dark-visual-mode"
            )}
        >
            {showSpinner && <Loader message={spinnerStatus.message} />}
            <div
                className={classNames(
                    "appstream-container-wrapper",
                    {
                        "On-bottom": showSpinner,
                    },
                    {
                        "Hide-visibility": showErrorModal,
                    }
                )}
            >
                {showFloatingToolbar && (
                    <FloatingToolbar appStreamSDK={appStreamSDK} />
                )}
                {showDockedToolbar && (
                    <DockedToolbar
                        appStreamSDK={appStreamSDK}
                        sessionState={sessionState}
                    />
                )}
                <div
                    ref={(div) => {
                        setIsAppStreamIframeRendered(!!div);
                    }}
                    className={"appstream-container"}
                    id={APPSTREAM_DIV_ID}
                />
            </div>
            <ConditionalRenderErrorModal
                visible={showErrorModal}
                sessionErrorType={sessionErrorType}
                appStreamSDK={appStreamSDK}
                onRedirectToLogin={onRedirectToLogin}
            />
        </div>
    );
};

EmbeddedStreamer.propTypes = {
    endpoint: PropTypes.string.isRequired,
};

export default EmbeddedStreamer;
