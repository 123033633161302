import { AppStreamSdkProps } from "../../../../types/Toolbar";
import { useToolbarPreferenceStore } from "../../../../hooks/useToolbarPreferenceStore";
import {
    ColumnLayout,
    FormField,
    RadioGroup,
    SpaceBetween,
    Toggle,
} from "@cloudscape-design/components";
import { AppStreamEmbedConstant } from "../../../../constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { isMacOS } from "../../../../utils/userAgentUtils";
import { usePreferenceNotificationStore } from "../../../../hooks/useNotificationStore";
import { NotificationItem } from "@amzn/aws-euc-ui";
import { useNotification } from "../notification/useNotification";
import log, { TOOLBAR_METRIC_NAME } from "../../../../logging";

const CommandKeySetting = ({ appStreamSdk }: AppStreamSdkProps) => {
    const { t } = useTranslation();

    const useCommandAsControlKey = useToolbarPreferenceStore(
        (store) => store.useCommandAsControlKey
    );
    const setUseCommandAsControlKey = useToolbarPreferenceStore(
        (store) => store.setUseCommandAsControlKey
    );

    const addNotification = usePreferenceNotificationStore(
        (store) => store.addNotification
    );

    const removeNotification = usePreferenceNotificationStore(
        (store) => store.removeNotification
    );

    const SET_COMMAND_KEY_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.setCommandKey.content"),
        removeNotification: removeNotification,
    });

    return (
        <FormField
            label={t(
                "toolbar.item.preference.content.tab.general.commandKey.label"
            )}
        >
            <RadioGroup
                onChange={async ({ detail }) => {
                    const selected = detail.value === String(true);
                    try {
                        await appStreamSdk.performActionPromise(
                            AppStreamEmbedConstant.METHOD_SET_KEYBOARD_SETTINGS,
                            {
                                useCommandAsControlKey: selected,
                            }
                        );
                        setUseCommandAsControlKey(selected);
                        if (selected) {
                            log.publishCounterMetric(
                                TOOLBAR_METRIC_NAME.SET_COMMAND_AS_CONTROL_KEY_SUCCESS
                            );
                        } else {
                            log.publishCounterMetric(
                                TOOLBAR_METRIC_NAME.SET_COMMAND_AS_META_KEY_SUCCESS
                            );
                        }
                    } catch {
                        addNotification(SET_COMMAND_KEY_ERROR);
                        if (selected) {
                            log.publishCounterMetric(
                                TOOLBAR_METRIC_NAME.SET_COMMAND_AS_CONTROL_KEY_ERROR
                            );
                        } else {
                            log.publishCounterMetric(
                                TOOLBAR_METRIC_NAME.SET_COMMAND_AS_META_KEY_ERROR
                            );
                        }
                    }
                }}
                value={String(useCommandAsControlKey)}
                items={[
                    {
                        value: String(true),
                        label: t(
                            "toolbar.item.preference.content.tab.general.commandKey.remoteCtrl.label"
                        ),
                    },
                    {
                        value: String(false),
                        label: t(
                            "toolbar.item.preference.content.tab.general.commandKey.remoteMeta.label"
                        ),
                    },
                ]}
            />
        </FormField>
    );
};

const OptionKeySettings = ({ appStreamSdk }: AppStreamSdkProps) => {
    const { t } = useTranslation();

    const useOptionKeyAsAltKey = useToolbarPreferenceStore(
        (store) => store.useOptionKeyAsAltKey
    );
    const setUseOptionKeyAsAltKey = useToolbarPreferenceStore(
        (store) => store.setUseOptionKeyAsAltKey
    );

    const addNotification = usePreferenceNotificationStore(
        (store) => store.addNotification
    );

    const removeNotification = usePreferenceNotificationStore(
        (store) => store.removeNotification
    );

    const SET_OPTION_KEY_ERROR: NotificationItem = useNotification({
        header: t("notification.fail.setOptionKey.content"),
        removeNotification: removeNotification,
    });

    return (
        <FormField
            label={t(
                "toolbar.item.preference.content.tab.general.optionKey.label"
            )}
        >
            <RadioGroup
                onChange={async ({ detail }) => {
                    const selected = detail.value === String(true);
                    try {
                        await appStreamSdk.performActionPromise(
                            AppStreamEmbedConstant.METHOD_SET_KEYBOARD_SETTINGS,
                            {
                                useOptionAsAltKey: selected,
                            }
                        );
                        setUseOptionKeyAsAltKey(selected);
                    } catch {
                        addNotification(SET_OPTION_KEY_ERROR);
                    }
                }}
                value={String(useOptionKeyAsAltKey)}
                items={[
                    {
                        value: String(true),
                        label: t(
                            "toolbar.item.preference.content.tab.general.optionKey.remoteAlt.label"
                        ),
                    },
                    {
                        value: String(false),
                        label: t(
                            "toolbar.item.preference.content.tab.general.optionKey.localMod.label"
                        ),
                    },
                ]}
            />
        </FormField>
    );
};

const ShowFunctionKeysToolbarItem = (): JSX.Element => {
    const { t } = useTranslation();

    const showFunctionKeysToolbarItem = useToolbarPreferenceStore(
        (store) => store.showFunctionKeysToolbarItem
    );
    const setShowFunctionKeysToolbarItem = useToolbarPreferenceStore(
        (store) => store.setShowFunctionKeysToolbarItem
    );

    return (
        <FormField
            label={t(
                "toolbar.item.preference.content.tab.general.keyboardFunctionInToolbar.label"
            )}
        >
            <Toggle
                onChange={({ detail }) => {
                    setShowFunctionKeysToolbarItem(detail.checked);
                }}
                checked={showFunctionKeysToolbarItem}
            >
                {showFunctionKeysToolbarItem
                    ? t(
                          "toolbar.item.preference.content.tab.general.keyboardFunctionInToolbar.enable"
                      )
                    : t(
                          "toolbar.item.preference.content.tab.general.keyboardFunctionInToolbar.disabled"
                      )}
            </Toggle>
        </FormField>
    );
};

/**
 * Preferences - Keyboard tab content
 */
export const KeyboardTabContent = ({
    appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
    return (
        <ColumnLayout columns={2}>
            {isMacOS() && (
                <SpaceBetween size={"l"}>
                    <OptionKeySettings appStreamSdk={appStreamSdk} />
                    <CommandKeySetting appStreamSdk={appStreamSdk} />
                </SpaceBetween>
            )}
            <ShowFunctionKeysToolbarItem />
        </ColumnLayout>
    );
};
