import React, { useEffect } from "react";
import {
    Box,
    Button,
    Modal,
    SpaceBetween,
} from "@cloudscape-design/components";
import "./ErrorModal.css";
import { Trans, useTranslation } from "react-i18next";
import { CameraIcon } from "../icon";

interface WebcamModalProps {
    dismissWebcamModal: () => void;
}

export const WebcamModal = ({
    dismissWebcamModal,
}: WebcamModalProps): JSX.Element => {
    // Prompt user for webcam permissions on modal open
    useEffect(() => {
        (async () => {
            await navigator.mediaDevices.getUserMedia({
                video: true,
            });
        })();
    }, []);

    const { t } = useTranslation();
    return (
        <Modal
            onDismiss={() => {
                dismissWebcamModal();
            }}
            visible={true}
            data-testid="webcam-modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            iconAlign="right"
                            onClick={() => dismissWebcamModal()}
                        >
                            <Trans
                                i18nKey={"presession.webcamModal.button.deny"}
                            ></Trans>
                        </Button>
                        <Button
                            iconAlign="right"
                            variant="primary"
                            onClick={() => {
                                dismissWebcamModal();
                                window.location.reload(); // Reload the page since browser permission is changed
                            }}
                        >
                            <Trans
                                i18nKey={"presession.webcamModal.button.allow"}
                            ></Trans>
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header={t("presession.webcamModal.title")}
        >
            <SpaceBetween direction="vertical" size="m">
                <CameraIcon />
                <div>
                    <Box variant="p" fontWeight="bold">
                        <Trans
                            i18nKey={"presession.webcamModal.header1"}
                        ></Trans>
                    </Box>
                    <Box variant="p">
                        <Trans
                            i18nKey={"presession.webcamModal.content1"}
                        ></Trans>
                    </Box>
                </div>
                <div>
                    <Box variant="p" fontWeight="bold">
                        <Trans
                            i18nKey={"presession.webcamModal.header2"}
                        ></Trans>
                    </Box>
                    <Box variant="p">
                        <Trans
                            i18nKey={"presession.webcamModal.content2"}
                            components={{ b: <strong /> }}
                        ></Trans>
                    </Box>
                </div>
                <Box variant="p">
                    <Trans i18nKey={"presession.webcamModal.content3"}></Trans>
                </Box>
            </SpaceBetween>
        </Modal>
    );
};
