import { create } from "zustand";
import { Application } from "../types/appStream";

/*
 * Chrome application display name will not be changed.
 * V1: https://tiny.amazon.com/aqs5wgp4/AWSLemmingServiceCDK/mainline/appblock.ts#L70
 * V2: https://tiny.amazon.com/1bddj8925/AWSLemmingApplicationCDK/mainline/appblock.ts#L70
 */
export const ApplicationName = {
    CHROME: "Chrome",
    IME_BAR: "Keyboard language selector",
    CLIENT_MESSAGE_HANDLER: "Client Message Handler",
};

interface ToolbarPreferenceTabIdStore {
    chromeBrowserApplicationName: string | undefined;
    keyboardInputToggleApplicationName: string | undefined;
    applications: Application[];
    setApplications: (applications: Application[]) => void;
}

/**
 * Store for AppStream applications
 * - Chrome browser application name
 */
export const useAppStreamApplications = create<ToolbarPreferenceTabIdStore>(
    (store) => ({
        chromeBrowserApplicationName: undefined,
        keyboardInputToggleApplicationName: undefined,
        clientMessageHandlerApplicationName: undefined,
        applications: [],
        setApplications: (applications) => {
            store((state) => ({
                ...state,
                applications: applications,
                chromeBrowserApplicationName: applications.find(
                    (app) => app.displayName === ApplicationName.CHROME
                )?.name,
                keyboardInputToggleApplicationName: applications.find(
                    (app) => app.displayName === ApplicationName.IME_BAR
                )?.name,
                clientMessageHandlerApplicationName: applications.find(
                    (app) =>
                        app.displayName ===
                        ApplicationName.CLIENT_MESSAGE_HANDLER
                )?.name,
            }));
        },
    })
);
