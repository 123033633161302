import { Cookies } from "webextension-polyfill";

export type CookieResult = {
    cookies: Cookies.Cookie[];
    error:
        | "EXTENSION_NOT_INSTALLED"
        | "CONFIG_FETCH_ERROR"
        | "EXTENSION_REQUEST_ERROR"
        | null;
};

export const CookieSyncSupportedBrowsers = [
    "Chrome",
    "Firefox",
    "Edge",
] as const;
export type CookieSyncSupportedBrowserType = typeof CookieSyncSupportedBrowsers[number];
