import { create } from "zustand";
import { NotificationItem } from "@amzn/aws-euc-ui";

interface UseNotificationListStoreState {
    notificationList: NotificationItem[];
    addNotification: (item: NotificationItem) => void;
    removeNotification: (id: string) => void;
}

export const useWindowsNotificationStore = create<
    UseNotificationListStoreState
>((set) => ({
    notificationList: [],
    addNotification: (item: NotificationItem) =>
        set((state) => ({
            notificationList: [...state.notificationList, item],
        })),
    removeNotification: (id: string) =>
        set((state) => ({
            notificationList: state.notificationList.filter(
                (item) => item.id !== id
            ),
        })),
}));

export const useFilesNotificationStore = create<UseNotificationListStoreState>(
    (set) => ({
        notificationList: [],
        addNotification: (item: NotificationItem) =>
            set((state) => ({
                notificationList: [...state.notificationList, item],
            })),
        removeNotification: (id: string) =>
            set((state) => ({
                notificationList: state.notificationList.filter(
                    (item) => item.id !== id
                ),
            })),
    })
);

export const usePreferenceNotificationStore = create<
    UseNotificationListStoreState
>((set) => ({
    notificationList: [],
    addNotification: (item: NotificationItem) =>
        set((state) => ({
            notificationList: [...state.notificationList, item],
        })),
    removeNotification: (id: string) =>
        set((state) => ({
            notificationList: state.notificationList.filter(
                (item) => item.id !== id
            ),
        })),
}));

// Gets used for floating and docked toolbar
export const useNotificationStore = create<UseNotificationListStoreState>(
    (set) => ({
        notificationList: [],
        addNotification: (newItem: NotificationItem) =>
            set((state) => ({
                // Only add the notification to bottom if it isn't already in the list (dedupe notifications)
                notificationList: [
                    ...state.notificationList.filter(
                        (item) => item.id !== newItem.id
                    ),
                    newItem,
                ],
            })),
        removeNotification: (id: string) =>
            set((state) => ({
                notificationList: state.notificationList.filter(
                    (item) => item.id !== id
                ),
            })),
    })
);

export const useNotificationToolbarItemStore = create<
    UseNotificationListStoreState
>((set) => ({
    notificationList: [],
    addNotification: (newItem: NotificationItem) =>
        set((state) => ({
            // Only add the notification to bottom if it isn't already in the list (dedupe notifications)
            notificationList: [
                ...state.notificationList.filter(
                    (item) => item.id !== newItem.id
                ),
                newItem,
            ],
        })),
    removeNotification: (id: string) =>
        set((state) => ({
            notificationList: state.notificationList.filter(
                (item) => item.id !== id
            ),
        })),
}));
