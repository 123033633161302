import { useSessionMetricStore } from "./useSessionMetricStore";
import { useEffect, useCallback } from "react";
import log, { METRIC_NAME } from "../logging";

const METRIC_PUBLISH_INTERVAL = 60000;

export const useEmitSessionPerformanceMetrics = (): void => {
    const emitMetricsCallback = useCallback(() => {
        const latestSessionMetric = useSessionMetricStore.getState()
            .latestMetric;

        if (!latestSessionMetric) {
            console.debug("No session performance metric");
            return;
        }
        log.publishNumericMetric(
            METRIC_NAME.SESSION_FPS,
            latestSessionMetric.fps
        );
        log.publishNumericMetric(
            METRIC_NAME.SESSION_LATENCY,
            latestSessionMetric.latency
        );
    }, []);

    useEffect(() => {
        emitMetricsCallback(); // Emit the first metric immediately.
        const intervalId = setInterval(() => {
            emitMetricsCallback();
        }, METRIC_PUBLISH_INTERVAL);

        return () => clearInterval(intervalId);
    }, []);
};
