// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./style.css";

const BrandedFooter = () => {
    const { t } = useTranslation();

    return (
        <div className="euc-branded-footer-container-style">
            <span className="euc-branded-footer-span-style">
                &copy;{" "}
                {`${moment("01/01/2008").format("YYYY")} - ${moment().format(
                    "YYYY"
                )}`}{" "}
                {t("euc.signIn.brandedFooter.awsAffiliates.label")}{" "}
                {t("euc.signIn.brandedFooter.allRightsReserved.label")}
            </span>
        </div>
    );
};

export default BrandedFooter;
