import log, { METRIC_NAME } from "../logging";

export function isHardwareCompatible(canvas: any): boolean {
    // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
    if (canvas == null) return true;

    const gl = canvas.getContext("webgl") as WebGLRenderingContext;
    let metricName = METRIC_NAME.HARDWARE_COMPATIBILITY_SUCCESS;
    let vendor = `unknown`;
    let renderer = `unknown`;
    if (gl) {
        const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
        if (debugInfo) {
            vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            if (vendor?.includes(`Google`)) {
                if (
                    renderer?.includes(`ANGLE`) &&
                    renderer?.includes(`Intel`) &&
                    renderer?.includes(`HD Graphics 4000`) &&
                    renderer?.includes(`OpenGL`)
                ) {
                    metricName = METRIC_NAME.HARDWARE_COMPATIBILITY_FAILURE;
                }
            }
        }
    }
    // Publish Hardware compatibility success/failure
    log.info(`Portal vendor is ${vendor}`);
    log.info(`Portal renderer is ${renderer}`);
    log.publishCounterMetric(metricName);
    return metricName === METRIC_NAME.HARDWARE_COMPATIBILITY_SUCCESS;
}
