import { useEffect, useRef } from "react";
import log from "../logging";
import { isFeatureEnabled } from "../configurations";

function useClipboardPermissions() {
    const prevStateRef = useRef(null);

    const clipboardPermissionsReloadFlag = isFeatureEnabled(
        "clipboardPermissionReload"
    );

    useEffect(() => {
        const checkClipboardPermission = async () => {
            try {
                const status = await navigator.permissions.query({
                    name: "clipboard-read",
                });
                prevStateRef.current = status.state;

                log.logMessage(`Clipboard permission: ${status.state}`);

                const onChange = (event) => {
                    const newStatus = event.target;
                    const prevState = prevStateRef.current;

                    prevStateRef.current = newStatus.state;

                    log.logMessage(
                        `Clipboard permission changed: ${status.state}`
                    );

                    if (
                        clipboardPermissionsReloadFlag &&
                        !(
                            prevState === "prompt" &&
                            newStatus.state === "denied"
                        ) &&
                        newStatus.state !== "prompt"
                    ) {
                        window.location.reload();
                    }
                };

                status.addEventListener("change", onChange);

                return () => {
                    status.removeEventListener("change", onChange);
                };
            } catch (error) {
                log.logMessage(`Error checking clipboard permission: ${error}`);
            }
        };

        checkClipboardPermission();
    }, []);
}

export default useClipboardPermissions;
