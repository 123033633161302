import React, { RefObject, forwardRef, useEffect, useMemo } from "react";
import {
    DockedToolbar as EucDockedToolbar,
    TOOLBAR_HEIGHT,
    TutorialAnnotationContext,
    useToolbarCollapseStore,
    useToolbarTutorialStore,
} from "@amzn/aws-euc-ui";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { useTranslation } from "react-i18next";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import { useWindows } from "../items/useWindows";
import { useFileToolbarItem } from "../items/useFileToolbarItem";
import { useClipboardToolbarItem } from "../items/useClipboardToolbarItem";
import { useDualMonitor } from "../items/useDualMonitor";
import { useMicrophone } from "../items/useMicrophone";
import { useFunctionKeysToolbarItem } from "../items/useFunctionKeysToolbarItem";
import { usePreferencesToolbarItem } from "../items/preference/usePreferencesToolbarItem";
import { useProfileToolbarItem } from "../items/user/useProfileToolbarItem";
import { useNotificationToolbarItem } from "../items/notification/useNotificationToolbarItem";
import { ToolbarMode } from "../../../constants/Toolbar";
import { useTutorial } from "../items/useTutorial";
import {
    getTutorialCookie,
    setTutorialCookie,
    TutorialI18nStrings,
} from "../../../utils/tutorialUtils";
import "./DockedToolbar.css";
import { useNotificationStore } from "../../../hooks/useNotificationStore";
import { useApplications } from "../items/useApplications";
import {
    getApplicationFlag,
    getMobileDeviceFlag,
} from "../../../utils/toolbarItemUtils";
import { useFullScreenToolbarItem } from "../items/useFullScreenToolbarItem";
import { useCamera } from "../items/useCamera";
import { isFeatureEnabled } from "../../../configurations";
import { SessionStatus } from "../../../types/appStream";
import { useMicrophoneWithDropdown } from "../items/useMicrophoneWithDropdown";
import { useCameraWithDropdown } from "../items/useCameraWithDropdown";

interface DockedToolbarInternalProps {
    appStreamSDK: AppStreamSDK;
    toolbarState?: ToolbarMode;
}

const useSetupDockedToolbar = () => {
    const { setShowTutorial, setTutorialCompleted } = useToolbarTutorialStore();
    const isTutorialFinished = useMemo(() => getTutorialCookie(), []);
    useEffect(() => {
        if (isTutorialFinished) {
            setShowTutorial(false);
            setTutorialCompleted(true);
        }
    }, [isTutorialFinished]);
};

/**
 * Filter out `undefined`, `null`, false, 0 items from the list
 */
const getConditionalArray = (...items) => items.filter((item) => !!item);

const isMediaDeviceSelectorFeatureEnabled = isFeatureEnabled(
    "mediaDeviceSelector"
);
const isDisableWebcamFeatureEnabled = isFeatureEnabled("disableWebcam");

export const DockedToolbarInternal = ({
    appStreamSDK,
    toolbarState,
}: DockedToolbarInternalProps): React.JSX.Element => {
    const { t } = useTranslation();
    useSetupDockedToolbar();

    const showFunctionKeysToolbarItem = useToolbarPreferenceStore(
        (store) => store.showFunctionKeysToolbarItem
    );
    const showApplicationToolbarItem = useMemo(() => getApplicationFlag(), []);

    const applicationToolbarItem = useApplications(appStreamSDK, toolbarState);
    const windowsToolbarItem = useWindows(appStreamSDK, toolbarState);
    const fileToolbarItem = useFileToolbarItem(appStreamSDK, toolbarState);
    const clipboardToolbarItem = useClipboardToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const dualMonitorToolbarItem = useDualMonitor(appStreamSDK);
    const fullScreenToolbarItem = useFullScreenToolbarItem(appStreamSDK);
    const microphoneToolbarItem = useMicrophone(appStreamSDK);
    const cameraToolbarItem = useCamera(appStreamSDK);
    const microphoneWithDropdownToolbarItem = useMicrophoneWithDropdown(
        appStreamSDK
    );
    const cameraWithDropdownToolbarItem = useCameraWithDropdown(appStreamSDK);
    const functionKeysToolbarItem = useFunctionKeysToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const preferenceToolbarItem = usePreferencesToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const profileToolbarItem = useProfileToolbarItem(
        appStreamSDK,
        toolbarState
    );
    const notificationItem = useNotificationToolbarItem(toolbarState);
    const dockedNotificationList = useNotificationStore(
        (state) => state.notificationList
    );

    const isMobileFlagOn = useMemo(() => getMobileDeviceFlag(), []);
    const { collapsed } = useToolbarCollapseStore();

    return (
        <EucDockedToolbar
            items={getConditionalArray(
                showApplicationToolbarItem && applicationToolbarItem,
                windowsToolbarItem,
                appStreamSDK.isFileExplorerEnabled() && fileToolbarItem,
                appStreamSDK.isClipboardEnabled() && clipboardToolbarItem,
                !isMobileFlagOn && dualMonitorToolbarItem,
                !isMobileFlagOn && fullScreenToolbarItem,
                !isMediaDeviceSelectorFeatureEnabled && microphoneToolbarItem,
                isMediaDeviceSelectorFeatureEnabled &&
                    microphoneWithDropdownToolbarItem,
                isMediaDeviceSelectorFeatureEnabled &&
                    !isDisableWebcamFeatureEnabled &&
                    cameraWithDropdownToolbarItem,
                showFunctionKeysToolbarItem && functionKeysToolbarItem,
                preferenceToolbarItem,
                notificationItem,
                profileToolbarItem
            )}
            isCollapsible={isMobileFlagOn}
            isCollapsed={collapsed}
            i18nStrings={{
                ariaLabelToolbar: t("toolbar.docked.ariaLabel"),
                ariaLabelExpandToolbarButton: t(
                    "toolbar.docked.expand.button.ariaLabel"
                ),
            }}
            withHotspot={true}
            dockedNotificationListProps={{
                items: dockedNotificationList,
                ariaLabel: t("notification.docked.list.ariaLabel"),
            }}
        />
    );
};

interface DockedToolbarProps extends DockedToolbarInternalProps {
    sessionState: SessionStatus;
}

export const DockedToolbar = forwardRef(function DockedToolbar(
    {
        appStreamSDK,
        toolbarState = ToolbarMode.Docked,
        sessionState,
    }: DockedToolbarProps,
    ref: RefObject<HTMLDivElement>
) {
    const tutorialI18nStrings = TutorialI18nStrings();
    const currentTutorial = useTutorial(toolbarState);

    if (sessionState === "Unknown") {
        // If the session is not started, we will put dummy div element to avoid triggering unnecessary resizing event.
        return <div style={{ height: `${TOOLBAR_HEIGHT}px` }} />;
    }

    return (
        <TutorialAnnotationContext
            tutorialI18nStrings={tutorialI18nStrings}
            currentTutorial={currentTutorial}
            onFinish={() => setTutorialCookie(true)}
        >
            <div ref={ref} className={"docked-toolbar-wrapper"}>
                <DockedToolbarInternal
                    appStreamSDK={appStreamSDK}
                    toolbarState={toolbarState}
                />
            </div>
        </TutorialAnnotationContext>
    );
});
