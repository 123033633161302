import React, { memo, useState } from "react";
import {
    Box,
    Button,
    Header,
    Icon,
    Link,
    SpaceBetween,
    Spinner,
    Tabs,
} from "@cloudscape-design/components";
import {
    IToolbarButtonActiveContentType,
    IToolbarButtonProps,
    IToolbarButtonStatefulModalProps,
    IToolbarButtonType,
    ToolbarActiveStateContentProps,
    ToolbarContainerItemContent,
    useToolbarCollapseStore,
} from "@amzn/aws-euc-ui";
import {
    FEEDBACK_EMAIL_ADDRESS,
    REBRANDED_FEEDBACK_EMAIL_ADDRESS,
    ToolbarItemId,
    ToolbarMode,
    ToolbarProfileTabId,
} from "../../../../constants/Toolbar";
import { AppStreamSDK } from "../../../../utils/AppStreamSDK";
import { Trans, useTranslation } from "react-i18next";
import { SessionPerformanceTabContent } from "./SessionPerformanceTabContent";
import { ValueWithLabel } from "../../ValueWithLabel";
import { HelpTabContent } from "./HelpTabContent";
import { useToolbarProfileTabIdStore } from "../../../../hooks/useToolbarProfileTabIdStore";
import { CopyButton } from "../../../general/CopyButton";
import { useSessionDataStore } from "../../../../hooks/useSessionDataStore";
import { useEmitSessionPerformanceMetrics } from "../../../../hooks/useEmitSessionPerformanceMetrics";
import {
    getRebrandedMessageIdIfExists,
    getRebrandedTranslation,
} from "../../../../i18n";
import { isFeatureEnabled } from "../../../../configurations";

/**
 * Profile - About WorkSpaces Web tab content
 */
const AboutTabContent = (): JSX.Element => {
    const { t } = useTranslation();
    return (
        <ValueWithLabel
            label={getRebrandedTranslation("login.icon.alt", t)}
            valueTestId={"about-wsw"}
        >
            <Trans
                i18nKey={getRebrandedMessageIdIfExists(
                    "toolbar.item.user.content.tab.about.content"
                )}
            />
        </ValueWithLabel>
    );
};

const feedbackEmailAddres = isFeatureEnabled("rebrand")
    ? REBRANDED_FEEDBACK_EMAIL_ADDRESS
    : FEEDBACK_EMAIL_ADDRESS;

const EmailCopyButton = (props: { children?: React.ReactNode }) => {
    const { t } = useTranslation();

    return (
        <CopyButton
            copyText={feedbackEmailAddres}
            successPopoverText={t(
                "toolbar.item.user.content.tab.feedback.email.popover.success"
            )}
            errorPopoverText={t(
                "toolbar.item.user.content.tab.feedback.email.popover.failed"
            )}
            copyIconAriaLabel={t(
                "toolbar.item.user.content.tab.feedback.email.copy.button.ariaLabel"
            )}
        >
            <Link href={`mailto:${feedbackEmailAddres}`} target="_blank">
                {props.children}
            </Link>
        </CopyButton>
    );
};

/**
 * Profile - Feedback tab content
 */
const FeedbackTabContent = (): JSX.Element => {
    const { t } = useTranslation();

    const sessionId = useSessionDataStore((store) => store.sessionID);

    return (
        <div data-testid={"feedback-tab"}>
            <SpaceBetween size="l">
                <Trans
                    i18nKey={getRebrandedMessageIdIfExists(
                        "toolbar.item.user.content.tab.feedback.description"
                    )}
                    values={{
                        emailAddress: feedbackEmailAddres,
                    }}
                >
                    {[<EmailCopyButton></EmailCopyButton>]}
                </Trans>
                <Box>
                    <Trans
                        i18nKey={getRebrandedMessageIdIfExists(
                            "toolbar.item.user.content.tab.feedback.sessionId.description"
                        )}
                    ></Trans>
                </Box>
                <ValueWithLabel
                    label={t(
                        "toolbar.item.user.content.tab.feedback.sessionId.label"
                    )}
                >
                    {sessionId == null ? (
                        <Spinner
                            data-testid={"loading-session-data-sessionId"}
                        />
                    ) : (
                        <>
                            <CopyButton
                                copyText={sessionId}
                                successPopoverText={
                                    <Trans
                                        i18nKey={
                                            "toolbar.item.user.content.tab.feedback.sessionId.popover.success"
                                        }
                                    />
                                }
                                errorPopoverText={
                                    <Trans
                                        i18nKey={
                                            "toolbar.item.user.content.tab.feedback.sessionId.popover.failed"
                                        }
                                    />
                                }
                                copyIconAriaLabel={t(
                                    "toolbar.item.user.content.tab.feedback.sessionId.copy.button.ariaLabel"
                                )}
                            />
                            <>{sessionId}</>
                        </>
                    )}
                </ValueWithLabel>
            </SpaceBetween>
        </div>
    );
};

export const useProfileToolbarItem = (
    appStreamSDK: AppStreamSDK,
    toolbarState: string
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
    const { t } = useTranslation();

    const activeTabId = useToolbarProfileTabIdStore((store) => store.activeId);
    const setActiveTabId = useToolbarProfileTabIdStore(
        (store) => store.setActiveId
    );
    const setUserEndSession = useSessionDataStore(
        (store) => store.setUserEndSession
    );
    const { setCollapsed } = useToolbarCollapseStore();

    // Emit session performance metrics every minute
    useEmitSessionPerformanceMetrics();

    const ProfileContent = () => {
        return (
            <>
                <SpaceBetween direction={"horizontal"} size={"m"}>
                    {toolbarState === ToolbarMode.Floating && (
                        <Header>
                            <Trans i18nKey={"toolbar.item.user.label"} />
                        </Header>
                    )}
                    <Button
                        variant="primary"
                        onClick={() => {
                            setUserEndSession(true);
                            appStreamSDK.endSession();
                        }}
                    >
                        <Trans
                            i18nKey={
                                "toolbar.item.user.content.signOut.button.label"
                            }
                        />
                    </Button>
                </SpaceBetween>
                <Tabs
                    onChange={({ detail }) => {
                        setActiveTabId(
                            detail.activeTabId as ToolbarProfileTabId
                        );
                    }}
                    activeTabId={activeTabId}
                    tabs={[
                        {
                            label: t(
                                "toolbar.item.user.content.tab.sessionPerformance.label"
                            ),
                            id: ToolbarProfileTabId.SESSION_PERFORMANCE,
                            content: <SessionPerformanceTabContent />,
                        },
                        {
                            label: t(
                                "toolbar.item.user.content.tab.feedback.label"
                            ),
                            id: ToolbarProfileTabId.FEEDBACK,
                            content: <FeedbackTabContent />,
                        },
                        {
                            label: t(
                                "toolbar.item.user.content.tab.help.label"
                            ),
                            id: ToolbarProfileTabId.HELP,
                            content: <HelpTabContent />,
                        },
                        {
                            label: getRebrandedTranslation(
                                "toolbar.item.user.content.tab.about.label",
                                t
                            ),
                            id: ToolbarProfileTabId.ABOUT,
                            content: <AboutTabContent />,
                        },
                    ]}
                />
            </>
        );
    };

    if (toolbarState === ToolbarMode.Docked) {
        return {
            type: IToolbarButtonType.STATEFUL,
            activeStateContentType: IToolbarButtonActiveContentType.MODAL,
            id: ToolbarItemId.PROFILE,
            label: t("toolbar.item.user.label"),
            icon: <Icon data-testid="profile-icon" name="user-profile" />,
            onClick: () => {
                setCollapsed(false);
            },
            ActiveStateContent: memo(
                (props: ToolbarActiveStateContentProps) => <ProfileContent />
            ),
            ariaLabelCloseModal: t("toolbar.item.user.content.close.ariaLabel"),
        };
    }

    return {
        type: IToolbarButtonType.STATEFUL,
        activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
        id: ToolbarItemId.PROFILE,
        label: t("toolbar.item.user.label"),
        ariaLabel: t("toolbar.item.user.ariaLabel"),
        icon: <Icon data-testid="profile-icon" name="user-profile" />,
        onClick: () => {
            setCollapsed(false);
        },
        ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
            <ToolbarContainerItemContent
                close={props.close}
                closeButtonAriaLabel={t(
                    "toolbar.item.user.content.close.ariaLabel"
                )}
                closeButtonTitle={t("toolbar.item.content.close.title")}
            >
                <ProfileContent />
            </ToolbarContainerItemContent>
        )),
    };
};
