import { cookieManagerInstance } from "../cookies";
import { CustomCookieIdentifier } from "../constants/ShortbreadConfig";
import {
    SessionStateCookie,
    SessionStateCookieSchema,
    StreamingURLCache,
} from "../types/sessionState";

/**
 * Get the Session State cookie.
 * @returns the state cookie if a valid cookie is found,
 * else empty SessionStateCookie
 */
const getSessionStateCookie = (): SessionStateCookie => {
    const tempCookie = JSON.parse(
        cookieManagerInstance.getSessionStorage(
            CustomCookieIdentifier.SESSION_STATE.name
        ) ?? "{}"
    );
    // Parse the cookie to make sure all data is valid before returning
    const parsedCookie = SessionStateCookieSchema.safeParse(tempCookie);
    if (parsedCookie.success) {
        return parsedCookie.data;
    } else {
        return {} as SessionStateCookie;
    }
};

/**
 * Validates the sets the session state cookie. Throws and error if
 * the cookie is invalid
 * @param cookie cookie maintaining session state
 * @returns true if successful, false otherwise
 */
const setSessionStateCookie = (cookie: SessionStateCookie): boolean => {
    let isSuccess = false;
    const parsedCookie = SessionStateCookieSchema.safeParse(cookie);
    if (parsedCookie.success) {
        isSuccess = true;
        cookieManagerInstance.setSessionStorage(
            CustomCookieIdentifier.SESSION_STATE.name,
            JSON.stringify(cookie)
        );
    }
    return isSuccess;
};

/**
 * This is a manager class wrapper for the session
 * state cookie. Allows for simple
 */
class SessionStateCookieManager {
    /**
     * Getter function for streaming URL Cache
     * @returns streamingURLCache Object
     */
    getStreamingURLCache(): StreamingURLCache | undefined {
        try {
            const sessionStateCookie = getSessionStateCookie();
            return sessionStateCookie.streamingURLCache;
        } catch {
            return undefined;
        }
    }

    /**
     * Setter function for streaming URL Cache
     * @param String to save in session state
     * @note Logs a warning error if unable to set the value
     */
    setStreamingURLCache(streamingURLCache: StreamingURLCache): boolean {
        let sessionStateCookie: SessionStateCookie;
        try {
            sessionStateCookie = getSessionStateCookie();
            sessionStateCookie.streamingURLCache = streamingURLCache;
            return setSessionStateCookie(sessionStateCookie);
        } catch {
            return false;
        }
    }

    /**
     * Getter function for hiding extension modal session state
     * @returns boolean or undefined
     */
    getHideExtensionModalSessionState(): boolean | undefined {
        try {
            const sessionStateCookie = getSessionStateCookie();
            return sessionStateCookie.hideExtensionModal;
        } catch {
            return undefined;
        }
    }

    /**
     * Setter function for hiding extension modal session state
     * @param boolean
     */
    setHideExtensionModalSessionState(hideExtensionModal: boolean): boolean {
        let sessionStateCookie: SessionStateCookie;
        try {
            sessionStateCookie = getSessionStateCookie();
            sessionStateCookie.hideExtensionModal = hideExtensionModal;
            return setSessionStateCookie(sessionStateCookie);
        } catch {
            return false;
        }
    }
}

/**
 * Export a central sessionStateCookieManager instance which will be globally
 * accessible through the webapp. In case we refer it from multiple location
 * with possibility of concurrency, we can put promises here to make things
 * synchronous.
 */
export const sessionStateCookieManager = new SessionStateCookieManager();
