// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { useEffect } from "react";
import {
    DEFAULT_CLIENT_CONFIG,
    getEnvInfo,
    getPortalConfig,
} from "../../../../configurations";
import { BrowserType } from "../../../../enums";
import log, { METRIC_NAME } from "../../../../logging";
import { ErmineServerSideRenderingService } from "@amzn/ermine-ssr-sdk";
import { AWSError } from "aws-sdk";
import {
    CreateRemoteBrowserRequest,
    CreateRemoteBrowserResponse,
} from "@amzn/ermine-ssr-sdk/clients/ermineserversiderenderingservice";
import {
    getBrowserLanguage,
    getBrowserTimeZone,
    getDeepLink,
} from "../../../../utils";
import browser from "webextension-polyfill";

interface Props {
    onBrowserCreation: (
        browserType: BrowserType,
        endpoint: string,
        metricName: METRIC_NAME
    ) => void;
    onBrowserCreationError: (
        err: AWSError,
        metricName: METRIC_NAME,
        requestId: string
    ) => void;
    cookies: browser.Cookies.Cookie[];
}

const OidcBrowserCreator = (props: Props): JSX.Element => {
    const { onBrowserCreation, onBrowserCreationError, cookies } = props;
    const portalConfig = getPortalConfig();
    const portalArn = portalConfig.portalArn;
    const { region } = getEnvInfo();
    const endpoint = `${window.location.origin}`;

    useEffect(() => {
        const ermineService = new ErmineServerSideRenderingService({
            ...DEFAULT_CLIENT_CONFIG,
            region: region,
            endpoint: `${window.location.origin}`,
        });

        ermineService.makeUnauthenticatedRequest(
            "createRemoteBrowser",
            {
                portalArn,
                sessionConfig: {
                    supportedLanguage: getBrowserLanguage(),
                    timeZone: getBrowserTimeZone(),
                    deepLink: getDeepLink(),
                },
                cookies: cookies?.length ? JSON.stringify(cookies) : null,
            } as CreateRemoteBrowserRequest,
            function (err: AWSError, data: CreateRemoteBrowserResponse) {
                if (err) {
                    log.error(
                        `OidcBrowserCreator ${err.statusCode} | ${err.code}: ${err.message}`
                    );
                    onBrowserCreationError(
                        err,
                        METRIC_NAME.CREATE_REMOTE_BROWSER,
                        this.requestId
                    );
                } else {
                    const { streamingUrl, browserType } = data;
                    onBrowserCreation(
                        browserType as BrowserType,
                        streamingUrl,
                        METRIC_NAME.CREATE_REMOTE_BROWSER
                    );
                }
            }
        );
    }, [
        cookies,
        endpoint,
        onBrowserCreation,
        onBrowserCreationError,
        portalArn,
        region,
    ]);

    return null;
};

export default OidcBrowserCreator;
