import { sessionStateCookieManager } from "./SessionStateCookieManager";
import { getHideExtensionModalCookieState } from "./bannerUtils";
import { getDeviceBrowser, isDeviceOnExperimentalMode } from "./userAgentUtils";
import { CookieSyncSupportedBrowsers } from "../types/cookies";

/**
 * Currently Cookie Sync feature only supports Chrome and Firefox.
 * @returns true if browser is supported, false otherwise
 */
export const isBrowserSupportedCookieSync = (): boolean => {
    const browserType = getDeviceBrowser();
    return (
        CookieSyncSupportedBrowsers.find(
            (browser) => browser === browserType
        ) !== undefined
    );
};
/**
 * returns if should hide extension modal based on session storage, cookie state, browser/device type and feature flag
 * - If user checks "don't show it again", we won't show it in same browser based on cookie state
 * - If user clicks "start session", we won't show it in same tab based on session storage
 * - If user is using unsupported browser, we won't show it
 * - If user is using mobile device, we won't show it
 * @returns true if extension modal needs to be hidden, false otherwise
 */
export const shouldHideExtensionModal = (): boolean => {
    let shouldHideModal = false;
    shouldHideModal =
        sessionStateCookieManager.getHideExtensionModalSessionState() ||
        getHideExtensionModalCookieState() ||
        !isBrowserSupportedCookieSync() ||
        isDeviceOnExperimentalMode();
    return shouldHideModal;
};
