import {
    useToolbarCollapseStore,
    useToolbarMetricsStore,
} from "@amzn/aws-euc-ui";
import { useSessionDataStore } from "./useSessionDataStore";
import { useCallback, useEffect, useState } from "react";
import log, { TOOLBAR_METRIC_NAME } from "../logging";
import { useToolbarPreferenceStore } from "./useToolbarPreferenceStore";
import { ToolbarMode } from "../constants/Toolbar";
import { shallow } from "zustand/shallow";

const METRIC_PUBLISH_INTERVAL = 60000;

export const useEmitSessionMetrics = (): void => {
    const {
        resetMicrophoneEnabledDuration,
        addMicrophoneEnabledDuration,
        setMicrophoneLastEnabledTime,
        resetFloatingToolbarEnabledDuration,
        addFloatingToolbarEnabledDuration,
        setFloatingToolbarLastEnabledTime,
    } = useSessionDataStore(
        (store) => ({
            resetMicrophoneEnabledDuration:
                store.resetMicrophoneEnabledDuration,
            addMicrophoneEnabledDuration: store.addMicrophoneEnabledDuration,
            setMicrophoneLastEnabledTime: store.setMicrophoneLastEnabledTime,
            resetFloatingToolbarEnabledDuration:
                store.resetFloatingToolbarEnabledDuration,
            addFloatingToolbarEnabledDuration:
                store.addFloatingToolbarEnabledDuration,
            setFloatingToolbarLastEnabledTime:
                store.setFloatingToolbarLastEnabledTime,
        }),
        shallow
    );

    const {
        addCollapsedDuration,
        setLastCollapsedTimestamp,
        resetCollapsedDuration,
        resetCollapseExpandClickCounts,
    } = useToolbarMetricsStore(
        (store) => ({
            addCollapsedDuration: store.addCollapsedDuration,
            setLastCollapsedTimestamp: store.setLastCollapsedTimestamp,
            resetCollapsedDuration: store.resetCollapsedDuration,
            resetCollapseExpandClickCounts:
                store.resetCollapseExpandClickCounts,
        }),
        shallow
    );

    const emitMicrophoneMetricsCallback = useCallback(() => {
        const intervalTime = performance.now();

        // 1. check if mic is enabled now
        // note: we use `getState()` to get the most updated
        // values from the store.
        if (useToolbarPreferenceStore.getState().microphoneEnabled) {
            // 2. if yes, add time to duration and update duration value and lastEnabledTime
            addMicrophoneEnabledDuration(
                intervalTime -
                    useSessionDataStore.getState().microphoneLastEnabledTime
            );
            setMicrophoneLastEnabledTime(intervalTime);

            log.publishNumericMetric(
                TOOLBAR_METRIC_NAME.MICROPHONE_ENABLED_DURATION,
                useSessionDataStore.getState().microphoneEnabledDuration
            );
        } else {
            // 3. if mic not enabled, emit duration and reset lastEnabledTime to undefined
            log.publishNumericMetric(
                TOOLBAR_METRIC_NAME.MICROPHONE_ENABLED_DURATION,
                useSessionDataStore.getState().microphoneEnabledDuration
            );
            // reset lastEnabledTime otherwise time will keep getting added
            setMicrophoneLastEnabledTime(undefined);
        }
        // 4. then reset duration
        resetMicrophoneEnabledDuration();
    }, []);

    const emitToolbarModeMetricsCallback = useCallback(() => {
        const intervalTime = performance.now();

        // 1. check if floating toolbar mode is enabled now
        if (
            useToolbarPreferenceStore.getState().toolbarMode ===
            ToolbarMode.Floating
        ) {
            // 2. if yes, add time to duration and update duration value and lastEnabledTime
            addFloatingToolbarEnabledDuration(
                intervalTime -
                    useSessionDataStore.getState()
                        .floatingToolbarLastEnabledTime
            );
            setFloatingToolbarLastEnabledTime(intervalTime);

            // 3. check if toolbar duration is a number (step 2 can sometimes
            // result in 0 - undefined = NaN, at the beginning of sessions)
            if (
                isNaN(
                    useSessionDataStore.getState()
                        .floatingToolbarEnabledDuration
                )
            ) {
                log.publishNumericMetric(
                    TOOLBAR_METRIC_NAME.FLOATING_TOOLBAR_MODE_ENABLED_DURATION,
                    0
                );
            } else {
                log.publishNumericMetric(
                    TOOLBAR_METRIC_NAME.FLOATING_TOOLBAR_MODE_ENABLED_DURATION,
                    useSessionDataStore.getState()
                        .floatingToolbarEnabledDuration
                );
            }
        } else {
            // 4. if floating toolbar mode not enabled, emit duration and reset lastEnabledTime to undefined
            log.publishNumericMetric(
                TOOLBAR_METRIC_NAME.FLOATING_TOOLBAR_MODE_ENABLED_DURATION,
                useSessionDataStore.getState().floatingToolbarEnabledDuration
            );
            // reset lastEnabledTime otherwise time will keep getting added
            setFloatingToolbarLastEnabledTime(undefined);
        }
        // 5. then reset duration
        resetFloatingToolbarEnabledDuration();
    }, []);

    const emitToolbarExpandedMetricsCallback = useCallback(() => {
        // Only emit this metric while toolbar is in floating mode.
        if (
            useToolbarPreferenceStore.getState().toolbarMode ===
            ToolbarMode.Floating
        ) {
            const intervalTime = performance.now();

            if (useToolbarCollapseStore.getState().collapsed) {
                addCollapsedDuration(
                    intervalTime -
                        useToolbarMetricsStore.getState().lastCollapsedTimestamp
                );
                setLastCollapsedTimestamp(intervalTime);
            } else {
                setLastCollapsedTimestamp(undefined);
            }
            let toolbarExpandedDuration =
                METRIC_PUBLISH_INTERVAL -
                useToolbarMetricsStore.getState().collapsedDuration;
            if (toolbarExpandedDuration < 0) {
                toolbarExpandedDuration = 0;
            }
            log.publishNumericMetric(
                TOOLBAR_METRIC_NAME.TOOLBAR_EXPANDED_DURATION,
                toolbarExpandedDuration
            );

            resetCollapsedDuration();

            // Emit toolbar button click count metrics
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.EXPAND_BUTTON_CLICK_COUNT,
                useToolbarMetricsStore.getState().expandButtonClickCount
            );
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.EXPAND_HANDLE_CLICK_COUNT,
                useToolbarMetricsStore.getState().expandHandleClickCount
            );
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.COLLAPSE_BUTTON_CLICK_COUNT,
                useToolbarMetricsStore.getState().collapseButtonClickCount
            );
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.COLLAPSE_HANDLE_CLICK_COUNT,
                useToolbarMetricsStore.getState().collapseHandleClickCount
            );

            resetCollapseExpandClickCounts();
        }
    }, []);

    useEffect(() => {
        emitMicrophoneMetricsCallback();
        emitToolbarModeMetricsCallback();
        emitToolbarExpandedMetricsCallback();
        const intervalId = setInterval(() => {
            emitMicrophoneMetricsCallback();
            emitToolbarModeMetricsCallback();
            emitToolbarExpandedMetricsCallback();
        }, METRIC_PUBLISH_INTERVAL);

        return () => clearInterval(intervalId);
    }, []);
};
