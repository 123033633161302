import React from "react";
import { AuthStatus } from "../../../enums";
import { AuthStrategy } from "../../../types";
import { ICredentials } from "@aws-amplify/core";
import SSOConfigurator from "./Configurator";
import SSOAuthChecker from "./AuthChecker";
import SSOAuthButton from "./AuthButton";
import EUCSSOAuthButton from "./EUCAuthButton";
import SSOSignOut from "./SignOut";
import { getLegacyFlag } from "../../../utils/toolbarItemUtils";

export class SSOAuthStrategy implements AuthStrategy {
    public configure(onConfigured: () => void): JSX.Element {
        return <SSOConfigurator onConfigured={onConfigured} />;
    }

    public checkAuth(
        onAuthCheck: (
            currentAuthStatus: AuthStatus,
            credentials?: ICredentials
        ) => void
    ): JSX.Element {
        return <SSOAuthChecker onAuthCheck={onAuthCheck} />;
    }

    public incomplete(): JSX.Element | null {
        return null;
    }

    public authButton(): JSX.Element {
        return getLegacyFlag() ? <SSOAuthButton /> : <EUCSSOAuthButton />;
    }

    public signOut(): JSX.Element {
        return <SSOSignOut />;
    }
}
