import { IToolbarButtonProps, IToolbarButtonType } from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { AppStreamEmbedConstant } from "../../../constants";
import { Trans, useTranslation } from "react-i18next";
import {
    useNotificationStore,
    useNotificationToolbarItemStore,
} from "../../../hooks/useNotificationStore";
import { useFloatingAndToolbarNotification } from "./notification/useNotification";
import { FirefoxPermissionIcon, ChromiumPopupBlockedIcon } from "../../icon";
import { getDeviceBrowser } from "../../../utils/userAgentUtils";
import {
    DUAL_MONITOR_KEYBOARD_SHORTCUT_DELAY_MS,
    DUAL_MONITOR_PERMISSION_BLOCKED_ERROR_MESSAGE,
    NotificationId,
} from "../../../constants/Toolbar";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";

const useGetNotifications = () => {
    const { t } = useTranslation();

    const dualMonitorError = useFloatingAndToolbarNotification({
        header: t("notification.fail.dualMonitor.header"),
        notificationId: NotificationId.DUAL_MONITOR_ERROR,
    });

    const dualMonitorPermissionError = useFloatingAndToolbarNotification({
        header: t("notification.fail.dualMonitor.permission.blocked.label"),
        content: (() => {
            switch (getDeviceBrowser()) {
                case "Chrome":
                    return (
                        <Trans
                            i18nKey={
                                "notification.fail.dualMonitor.permission.blocked.content.chrome"
                            }
                        >
                            {[<ChromiumPopupBlockedIcon />]}
                        </Trans>
                    );
                case "Firefox":
                    return (
                        <Trans
                            i18nKey={
                                "notification.fail.dualMonitor.permission.blocked.content.firefox"
                            }
                        >
                            {[<FirefoxPermissionIcon />]}
                        </Trans>
                    );
                case "Safari":
                    return (
                        <Trans
                            i18nKey={
                                "notification.fail.dualMonitor.permission.blocked.content.safari"
                            }
                        />
                    );
                case "Edge":
                    return (
                        <Trans
                            i18nKey={
                                "notification.fail.dualMonitor.permission.blocked.content.edge"
                            }
                        >
                            {[<ChromiumPopupBlockedIcon />]}
                        </Trans>
                    );
            }
        })(),
        notificationId: NotificationId.DUAL_MONITOR_PERMISSION_ERROR,
    });

    return {
        dualMonitorError,
        dualMonitorPermissionError,
    };
};

export const useDualMonitor = (
    appStreamEmbed: AppStreamSDK
): IToolbarButtonProps => {
    const { t } = useTranslation();

    const addFloatingNotification = useNotificationStore(
        (store) => store.addNotification
    );
    const addToolbarNotification = useNotificationToolbarItemStore(
        (store) => store.addNotification
    );

    const {
        dualMonitorError,
        dualMonitorPermissionError,
    } = useGetNotifications();

    return {
        type: IToolbarButtonType.STATELESS,
        label: t("toolbar.item.dualMonitor.label"),
        ariaLabel: t("toolbar.item.dualMonitor.ariaLabel"),
        icon: <Icon data-testid={"dual-monitor-icon"} name="multiscreen" />,
        onClick: async () => {
            try {
                appStreamEmbed.openNewChromeWindow();
                await appStreamEmbed.performActionPromise(
                    AppStreamEmbedConstant.METHOD_TOGGLE_MULTI_MONITOR
                );
                await new Promise((resolve) =>
                    setTimeout(resolve, DUAL_MONITOR_KEYBOARD_SHORTCUT_DELAY_MS)
                );
                appStreamEmbed.moveChromeWindowToRight();
                log.publishCounterMetric(
                    TOOLBAR_METRIC_NAME.DUAL_MONITOR_SUCCESS
                );
            } catch (err) {
                // TODO closing a window can cause locking the screen issue. investigating... (https://issues.amazon.com/issues/LOWA-11515)
                // appStreamEmbed.closeWindow();
                log.publishCounterMetric(
                    TOOLBAR_METRIC_NAME.DUAL_MONITOR_ERROR
                );
                if (
                    err.errorMessage ===
                    DUAL_MONITOR_PERMISSION_BLOCKED_ERROR_MESSAGE
                ) {
                    addFloatingNotification(
                        dualMonitorPermissionError.floatingToolbarNotification
                    );
                    addToolbarNotification(
                        dualMonitorPermissionError.toolbarNotification
                    );
                } else {
                    addFloatingNotification(
                        dualMonitorError.floatingToolbarNotification
                    );
                    addToolbarNotification(
                        dualMonitorError.toolbarNotification
                    );
                }
            }
        },
    };
};
