import { cookieManagerInstance } from "../cookies";
import { CustomCookieIdentifier } from "../constants/ShortbreadConfig";
import {
    DEEP_LINK_URL_LENGTH_MIN,
    DEEP_LINK_URL_LENGTH_MAX,
    DEEP_LINK_LIST_LENGTH_MAX,
} from "../constants/clientMessage";
import { URL_PARAMETER, URL_REG_EX } from "../constants";
import log from "../logging";

const DEEP_LINKS_PARAM = `${URL_PARAMETER.DEEP_LINKS}=`;
const DEEP_LINKS_PARAM_AT_START = `?${DEEP_LINKS_PARAM}`;
const DEEP_LINKS_PARAM_NOT_AT_START = `&${DEEP_LINKS_PARAM}`;

export function getValidDeepLinks(
    deepLinks: string,
    errorCallbackFn: (invalidUrl: boolean, limitExceeded: boolean) => void
): string[] | null {
    if (!deepLinks) {
        return null;
    }

    let invalidUrl = false;
    let limitExceeded = false;

    let count = 0;
    const validDeepLinks = deepLinks
        .split(",")
        .map((deepLink) => decodeURIComponent(deepLink))
        .filter((decodedDeepLink) => {
            count++;
            if (!validateDeepLink(decodedDeepLink)) {
                invalidUrl = true;
                // TODO: Collect unhandled deep links and show info in UI
                log.warn(`Removing invalid deep link at position #${count}`);
                return false;
            }
            return true;
        })
        .map((deepLink) => new URL(deepLink).href);

    if (!validDeepLinks?.length) {
        errorCallbackFn(invalidUrl, limitExceeded);
        return null;
    }

    if (validDeepLinks.length > DEEP_LINK_LIST_LENGTH_MAX) {
        limitExceeded = true;
        // TODO: Collect unhandled deep links and show info in UI
        log.warn(
            `Too many deep links. Truncating to ${DEEP_LINK_LIST_LENGTH_MAX}`
        );
        validDeepLinks.length = DEEP_LINK_LIST_LENGTH_MAX;
    }

    errorCallbackFn(invalidUrl, limitExceeded);
    return validDeepLinks;
}

/**
 * Get the start index and end index of deep links param from a query.
 * @param query of an URL
 * @returns start index and end index in an object as Interval type
 * Example:
 *   query: "?deepLinks=URL&a=b"
 *   index:  012345678901234567
 *   output: { start: 1, end: 14 }
 */
export function getDeepLinksParamIndexInterval(
    query: string
): { start: number; end: number } {
    let startIndex: number;
    let endIndex: number;
    if (query.startsWith(DEEP_LINKS_PARAM_AT_START)) {
        // Deep links used in the 1st param.
        // e.g. "?deepLinks=URL1,URL2" or "?deepLinks=URL1,URL2&param=xyz"
        startIndex = 1; // Skip "?"
        const nextParamIndex = query.indexOf("&", startIndex);
        endIndex = nextParamIndex !== -1 ? nextParamIndex : query.length;
    } else {
        startIndex = query.indexOf(DEEP_LINKS_PARAM_NOT_AT_START);
        if (startIndex === -1) {
            return null;
        }
        // Deep links NOT used in the 1st param.
        // e.g. "?param=xyz&deepLinks=URL1,URL2" or "?param=xyz&deepLinks=URL1,URL2&param2=xyz"
        startIndex++; // Skip "&"
        const nextParamIndex = query.indexOf("&", startIndex);
        endIndex = nextParamIndex !== -1 ? nextParamIndex : query.length;
    }

    return { start: startIndex, end: endIndex };
}

export function getDeepLinksFromUrlParams(): string {
    const query = window.location.search;
    if (!query) {
        return null;
    }

    // The output values of URLSearchParams are decoded so we cannot use it. Otherwise, we are not
    // able to split URLs in deep links by "," if any URL has "," in its query as well.
    const interval = getDeepLinksParamIndexInterval(query);
    if (!interval) {
        return null;
    }

    return query.substring(
        interval.start + DEEP_LINKS_PARAM.length,
        interval.end
    );
}

export function removeDeepLinksFromUrlParams(): void {
    // The output of "new URLSearchParams(query).toString()" is encoded so it may not be the exactly
    // same as origin. We don't leverage URLSearchParams here to keep other params intact.
    const query = window.location.search;
    if (!query) {
        return;
    }

    const interval = getDeepLinksParamIndexInterval(query);
    if (!interval) {
        return;
    }

    const newQuery =
        query.substring(0, interval.start) + query.substring(interval.end);

    // Use window.history.pushState instead of window.location.href so we modify query without
    // navigation.
    window.history.pushState({}, null, `${newQuery}${window.location.hash}`);
}

export function validateDeepLink(deepLinkURL: string | null): boolean {
    if (!deepLinkURL) {
        return false;
    }
    if (
        deepLinkURL.length < DEEP_LINK_URL_LENGTH_MIN ||
        deepLinkURL.length > DEEP_LINK_URL_LENGTH_MAX
    ) {
        return false;
    }

    try {
        new URL(deepLinkURL);
    } catch (error) {
        return false;
    }

    const urlRegExp = new RegExp(URL_REG_EX, "gm");
    return urlRegExp.test(deepLinkURL);
}

export function getDeepLink(): string | null {
    const url =
        cookieManagerInstance.getSessionStorage(
            CustomCookieIdentifier.DEEPLINK.name
        ) ?? null;
    if (url === "null") {
        return null;
    }
    return url;
}
