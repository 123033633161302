import React from "react";
import { IToolbarButtonProps, ToolbarButton } from "@amzn/aws-euc-ui";
import { SpaceBetween } from "@cloudscape-design/components";

interface MinimizedViewProps {
    items: IToolbarButtonProps[];
}

export const MinimizedView = (props: MinimizedViewProps): React.JSX.Element => {
    return (
        <div
            className={"wsw-minimized-view"}
            data-testid={"minimized-toolbar-view"}
        >
            <SpaceBetween direction={"horizontal"} size={"xl"}>
                {props.items.map(({ label, ...itemExceptLabel }) => (
                    /*
                     * Make the label undefined to show the button without a label.
                     * Toolbar button will change the button styles automatically when a label
                     * is empty.
                     */
                    <ToolbarButton
                        key={
                            label ??
                            itemExceptLabel.ariaLabel ??
                            itemExceptLabel.testId
                        }
                        {...itemExceptLabel}
                        label={undefined}
                        title={label}
                    />
                ))}
            </SpaceBetween>
        </div>
    );
};
