import React, { memo } from "react";
import {
    IToolbarButtonActiveContentType,
    IToolbarButtonProps,
    IToolbarButtonType,
    ToolbarActiveStateContentProps,
    ToolbarContainerItemContent,
    NotificationList as EucNotificationList,
    useToolbarCollapseStore,
    IToolbarButtonStatefulModalProps,
} from "@amzn/aws-euc-ui";
import { Trans, useTranslation } from "react-i18next";
import { ToolbarItemId, ToolbarMode } from "../../../../constants/Toolbar";
import { NotificationIconWithBadge } from "./NotificationIcon";
import { useNotificationToolbarItemStore } from "../../../../hooks/useNotificationStore";
import Header from "@cloudscape-design/components/header";
import TextContent from "@cloudscape-design/components/text-content";

export const useNotificationToolbarItem = (
    toolbarState: ToolbarMode
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
    const { t } = useTranslation();
    const notificationList = useNotificationToolbarItemStore(
        (state) => state.notificationList
    );
    const { collapsed, setCollapsed } = useToolbarCollapseStore();
    const badgeNumber = notificationList.length;

    const NotificationContent = () => {
        return (
            <>
                {badgeNumber === 0 ? (
                    <TextContent>
                        <p style={{ textAlign: "center", fontWeight: "700" }}>
                            <Trans
                                i18nKey={
                                    "toolbar.item.notifications.empty.message"
                                }
                            />
                        </p>
                    </TextContent>
                ) : (
                    <EucNotificationList items={notificationList} />
                )}
            </>
        );
    };

    if (toolbarState === ToolbarMode.Docked) {
        return {
            type: IToolbarButtonType.STATEFUL,
            activeStateContentType: IToolbarButtonActiveContentType.MODAL,
            id: ToolbarItemId.NOTIFICATIONS,
            label: t("toolbar.item.notifications.label"),
            icon: <NotificationIconWithBadge badgeNumber={badgeNumber} />,
            onClick: () => {
                setCollapsed(false);
            },
            ActiveStateContent: memo(() => <NotificationContent />),
            ariaLabelCloseModal: t(
                "toolbar.item.notifications.content.close.ariaLabel"
            ),
        };
    }

    return {
        type: IToolbarButtonType.STATEFUL,
        activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
        id: ToolbarItemId.NOTIFICATIONS,
        label: t("toolbar.item.notifications.label"),
        ariaLabel: t("toolbar.item.notifications.ariaLabel"),
        icon: <NotificationIconWithBadge badgeNumber={badgeNumber} />,
        onClick: () => {
            if (collapsed) {
                setCollapsed(false);
            }
        },
        ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
            <ToolbarContainerItemContent
                close={props.close}
                closeButtonAriaLabel={t(
                    "toolbar.item.notifications.content.close.ariaLabel"
                )}
                closeButtonTitle={t("toolbar.item.content.close.title")}
            >
                <Header variant={"h2"}>
                    <Trans
                        i18nKey={"toolbar.item.notifications.content.header"}
                    />
                </Header>
                <NotificationContent />
            </ToolbarContainerItemContent>
        )),
    };
};
