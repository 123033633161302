// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React, { useEffect, useMemo, useState } from "react";
import {
    AuthStatus,
    BrowserType,
    ErrorType,
    LandingPageState,
} from "../../enums";
import { AuthState } from "../../types";
import BrowserFactory from "../browser/BrowserFactory";
import EmbeddedStreamer from "../browser/appStream/EmbeddedStreamer";
import log, { METRIC_NAME } from "../../logging";
import Authentication from "../authentication/Authentication";
import SignOut from "../authentication/SignOut";
import WarningAlert from "../general/WarningAlert";
import {
    isDeviceOnExperimentalMode,
    isWebcamSupportedWithDocker,
} from "../../utils/userAgentUtils";
import { useTranslation } from "react-i18next";
import ErrorAlert from "../general/ErrorAlert";
import { ERROR_STATE } from "../../constants";
import { useSetCookiesFromUrlParams } from "../../hooks/useSetCookiesFromUrlParams";
import {
    getLegacyFlag,
    getMobileDeviceFlag,
    isBrowserPermissionGranted,
} from "../../utils/toolbarItemUtils";
import { CookieResult } from "../../types/cookies";
import { ExtensionModal } from "../presession/ExtensionModal";
import { WebcamModal } from "../presession/WebcamModal";
import CookieManager from "../browser/cookies/CookieManager";
import { shouldHideExtensionModal } from "../../utils/cookieSyncUtils";
import { useCookieResultForNotification } from "../../hooks/useCookieResult";
import { useSetDeepLinkFromUrlParam } from "../../hooks/useSetDeepLinkFromUrlParam";
import { getRebrandedTranslation } from "../../i18n";

export const App = (): JSX.Element => {
    const settingCookiesFromUrlParamsStatus = useSetCookiesFromUrlParams();
    const settingDeepLinkStatus = useSetDeepLinkFromUrlParam();

    const [authState, setAuthState] = useState<AuthState>({
        authStatus: AuthStatus.SIGNED_OUT,
        credentials: null,
    });
    const [browser, setBrowser] = useState<{
        browserType: BrowserType;
        browserEndpoint: string;
    } | null>(null);
    const [errorState, setErrorState] = useState(() => {
        const errorCode = new URLSearchParams(window.location.search).get(
            "error"
        );
        const errorDescription = new URLSearchParams(
            window.location.search
        ).get("error_description");
        switch (errorCode) {
            case ErrorType.UNAUTHORIZED: {
                if (errorDescription) {
                    return {
                        errorState: ERROR_STATE.ERROR,
                        errorHeader: "authentication.errorAlert.error.header",
                        errorMessage: errorDescription,
                        landingPageState: LandingPageState.FULL_PAGE,
                    };
                } else {
                    return {
                        errorState: ERROR_STATE.INFO,
                        errorHeader: "login.errorAlert.unauthorized.header",
                        errorMessage: "login.errorAlert.unauthorized.message",
                        landingPageState: LandingPageState.BANNER_ONLY,
                    };
                }
            }
            default: {
                return {
                    errorState: ERROR_STATE.NONE,
                    errorHeader: null,
                    errorMessage: null,
                };
            }
        }
    });
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [showWebcamModal, setShowWebcamModal] = useState(false);

    useEffect(() => {
        (async () => {
            if (
                !(await isBrowserPermissionGranted("camera")) &&
                isWebcamSupportedWithDocker()
            ) {
                setShowWebcamModal(true);
            }
        })();
    }, []);

    const [cookieResult, setCookieResult] = useState<CookieResult>(null);
    const onCookiesResult = (cookieResult: CookieResult) => {
        setCookieResult(cookieResult);
    };
    const hideExtensionModalFlag = useMemo(
        () => shouldHideExtensionModal(),
        []
    );

    const dismissExtensionModal = () => {
        setCookieResult({
            cookies: [],
            error: null,
        });
    };

    useCookieResultForNotification(cookieResult);

    const { t } = useTranslation();

    useEffect(() => {
        log.publishCounterMetric(METRIC_NAME.REFRESH);

        /*
      Track page refresh events using an experimental api, this is not supported in
      iE or safari. The alternative to listen to page refresh page events will never work
      because the publish fn is async and batched so it will not finish before the browser
      kills the thread.
    */
        if (performance.getEntriesByType) {
            const perfEntries = performance.getEntriesByType("navigation");
            // eslint-disable-next-line
      // @ts-ignore spec is here https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming/type
            if (perfEntries[0] && perfEntries[0].type === "reload") {
                log.publishCounterMetric(METRIC_NAME.CLICK);
            }
        }
    }, []);

    const isLegacy = useMemo(
        // TODO For mobile users, present the legacy experience until we can resolve the on-screen keyboard issue. (https://issues.amazon.com/issues/LOWA-11655)
        () => getLegacyFlag() || isDeviceOnExperimentalMode(),
        [settingCookiesFromUrlParamsStatus]
    );

    const isMobileFlagOn = useMemo(() => getMobileDeviceFlag(), [
        settingCookiesFromUrlParamsStatus,
    ]);

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0];
        if (!body) {
            return;
        }
        if (isLegacy && !isMobileFlagOn) {
            body.classList.add("body-background-style");
            body.classList.remove("euc-body-background-style");
        } else {
            body.classList.add("euc-body-background-style");
            body.classList.remove("body-background-style");
        }
    }, [isLegacy, isMobileFlagOn]);

    // Wait until cookies from URL params are loaded
    if (settingCookiesFromUrlParamsStatus !== "completed") {
        return <></>;
    }
    // Wait until deeplink is stored in sessionStorage
    if (settingDeepLinkStatus !== "completed") {
        return <></>;
    }

    if (authState.authStatus === AuthStatus.SIGNED_IN && !redirectToLogin) {
        // Valid user, render the main page
        if (showWebcamModal) {
            return (
                <WebcamModal
                    dismissWebcamModal={() => setShowWebcamModal(false)}
                />
            );
        } else if (cookieResult == null) {
            return (
                <CookieManager
                    onCookiesResult={onCookiesResult}
                    sigv4Credentials={authState.credentials}
                />
            );
        } else if (
            cookieResult.error === "EXTENSION_NOT_INSTALLED" &&
            !hideExtensionModalFlag
        ) {
            return (
                <ExtensionModal
                    dismissExtensionModal={dismissExtensionModal}
                    onRedirectToLogin={setRedirectToLogin}
                />
            );
        } else if (!browser) {
            return (
                <BrowserFactory
                    onBrowserCreation={(browserType, browserEndpoint) => {
                        setBrowser({
                            browserType,
                            browserEndpoint,
                        });
                    }}
                    sigv4Credentials={authState.credentials}
                    cookieResult={cookieResult}
                    onRedirectToLogin={setRedirectToLogin}
                />
            );
        } else {
            switch (browser.browserType) {
                case BrowserType.APPSTREAM: {
                    return (
                        <>
                            <WarningAlert
                                title={getRebrandedTranslation(
                                    "experimentalMode.alert.title",
                                    t
                                )}
                                message={getRebrandedTranslation(
                                    "experimentalMode.alert.message",
                                    t
                                )}
                                render={isDeviceOnExperimentalMode()}
                            />
                            <EmbeddedStreamer
                                endpoint={browser.browserEndpoint}
                                onSignOut={setAuthState}
                                onRedirectToLogin={setRedirectToLogin}
                            />
                        </>
                    );
                }
            }
        }
    } else if (authState.authStatus === AuthStatus.SIGNING_OUT) {
        return <SignOut />;
    } else if (errorState.errorState !== ERROR_STATE.NONE) {
        if (LandingPageState.BANNER_ONLY === errorState.landingPageState) {
            return (
                <div className="awsui On-top">
                    <ErrorAlert
                        type={errorState.errorState}
                        header={getRebrandedTranslation(
                            errorState.errorHeader,
                            t
                        )}
                        message={getRebrandedTranslation(
                            errorState.errorMessage,
                            t
                        )}
                        onDismissErrorAlert={() => {
                            setErrorState({
                                errorState: ERROR_STATE.NONE,
                                errorHeader: null,
                                errorMessage: null,
                            });
                        }}
                    />
                </div>
            );
        } else {
            return (
                <Authentication
                    onSignIn={setAuthState}
                    errorState={errorState}
                />
            );
        }
    } else {
        return <Authentication onSignIn={setAuthState} />;
    }
};

export default App;
