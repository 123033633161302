import { FunctionKeyId } from "@amzn/aws-euc-ui";
import { ExperimentalFlags, ValueOf } from "../types";
import { AppStreamEmbedConstant } from "../constants";
import { AdditionalKeyId } from "../constants/Toolbar";
import { cookieManagerInstance } from "../cookies";
import { CustomCookieIdentifier } from "../constants/ShortbreadConfig";
import { Interfaces } from "@amzn/photon-portal-embed-sdk";
import EmbedKeyboardKey = Interfaces.EmbedKeyboardKey;

// TODO remove all instances of the legacy flag from codebase
// https://sim.amazon.com/issues/LOWA-15963
export const getLegacyFlag = () => {
    try {
        const experimentalFlags = JSON.parse(
            cookieManagerInstance.getSessionStorage(
                CustomCookieIdentifier.EXPERIMENTAL.name
            ) ?? "{}"
        ) as ExperimentalFlags;
        return experimentalFlags.legacy ?? false;
    } catch {
        return false;
    }
};

export const getMobileDeviceFlag = () => {
    try {
        const experimentalFlags = JSON.parse(
            cookieManagerInstance.getSessionStorage(
                CustomCookieIdentifier.EXPERIMENTAL.name
            ) ?? "{}"
        ) as ExperimentalFlags;
        return experimentalFlags.mobileDevice ?? false;
    } catch {
        return false;
    }
};

export const getApplicationFlag = () => {
    try {
        const experimentalFlags = JSON.parse(
            cookieManagerInstance.getSessionStorage(
                CustomCookieIdentifier.EXPERIMENTAL.name
            ) ?? "{}"
        ) as ExperimentalFlags;
        return experimentalFlags.applications ?? false;
    } catch {
        return false;
    }
};

export const getDirectImportFlag = () => {
    try {
        const experimentalFlags = JSON.parse(
            cookieManagerInstance.getSessionStorage(
                CustomCookieIdentifier.EXPERIMENTAL.name
            ) ?? "{}"
        ) as ExperimentalFlags;
        return experimentalFlags.directImport ?? false;
    } catch {
        return false;
    }
};

const {
    EmbedKeyboardLocation: { DOM_KEY_LOCATION_STANDARD, DOM_KEY_LOCATION_LEFT },
} = AppStreamEmbedConstant;

// TODO need to send "hold key" event (CTRL, ALT SHIFT)
// https://issues.amazon.com/issues/LOWA-9334
export const convertToolbarKeyIdIntoAppStreamKey = (event: {
    keyId: ValueOf<typeof FunctionKeyId>;
    ctrlKey: boolean;
    altKey: boolean;
    shiftKey: boolean;
}): EmbedKeyboardKey[] => {
    const keys: EmbedKeyboardKey[] = [];
    if (event.ctrlKey) {
        keys.push({ key: "Control", location: DOM_KEY_LOCATION_LEFT });
    }
    if (event.altKey) {
        keys.push({ key: "Alt", location: DOM_KEY_LOCATION_LEFT });
    }
    if (event.shiftKey) {
        keys.push({ key: "Shift", location: DOM_KEY_LOCATION_LEFT });
    }

    switch (event.keyId) {
        case FunctionKeyId.controlKey:
            return [
                ...keys,
                { key: "Control", location: DOM_KEY_LOCATION_LEFT },
            ];
        case FunctionKeyId.altKey:
            return [...keys, { key: "Alt", location: DOM_KEY_LOCATION_LEFT }];
        case FunctionKeyId.shiftKey:
            return [...keys, { key: "Shift", location: DOM_KEY_LOCATION_LEFT }];
        case FunctionKeyId.deleteKey:
            return [
                ...keys,
                { key: "Delete", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.escapeKey:
            return [
                ...keys,
                { key: "Escape", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.tabKey:
            return [
                ...keys,
                { key: "Tab", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.homeKey:
            return [
                ...keys,
                { key: "Home", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.endKey:
            return [
                ...keys,
                { key: "End", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.leftArrowKey:
            return [
                ...keys,
                { key: "ArrowLeft", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.rightArrowKey:
            return [
                ...keys,
                { key: "ArrowRight", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.upArrowKey:
            return [
                ...keys,
                { key: "ArrowUp", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.downArrowKey:
            return [
                ...keys,
                { key: "ArrowDown", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.enterKey:
            return [
                ...keys,
                { key: "Enter", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f1Key:
            return [
                ...keys,
                { key: "F1", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f2Key:
            return [
                ...keys,
                { key: "F2", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f3Key:
            return [
                ...keys,
                { key: "F3", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f4Key:
            return [
                ...keys,
                { key: "F4", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f5Key:
            return [
                ...keys,
                { key: "F5", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f6Key:
            return [
                ...keys,
                { key: "F6", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f7Key:
            return [
                ...keys,
                { key: "F7", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f8Key:
            return [
                ...keys,
                { key: "F8", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f9Key:
            return [
                ...keys,
                { key: "F9", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f10Key:
            return [
                ...keys,
                { key: "F10", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f11Key:
            return [
                ...keys,
                { key: "F11", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case FunctionKeyId.f12Key:
            return [
                ...keys,
                { key: "F12", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case AdditionalKeyId.SELECT_ALL:
            return [
                ...keys,
                { key: "Control", location: DOM_KEY_LOCATION_LEFT },
                { key: "a", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case AdditionalKeyId.COPY:
            return [
                ...keys,
                { key: "Control", location: DOM_KEY_LOCATION_LEFT },
                { key: "c", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case AdditionalKeyId.PASTE:
            return [
                ...keys,
                { key: "Control", location: DOM_KEY_LOCATION_LEFT },
                { key: "v", location: DOM_KEY_LOCATION_STANDARD },
            ];
        case AdditionalKeyId.CUT:
            return [
                ...keys,
                { key: "Control", location: DOM_KEY_LOCATION_LEFT },
                { key: "x", location: DOM_KEY_LOCATION_STANDARD },
            ];
    }
};

/**
 * Extract subset of the raw data and convert it to the CloudScape Line chart data format
 * https://cloudscape.design/components/line-chart/
 */
export const convertToLastNcountSessionMetricData = (
    rawData: number[],
    maxCount: number
) =>
    rawData.slice(-maxCount).map((value, index, arr) => ({
        x: arr.length - index - 1,
        y: value,
    }));

/**
 * Check a latency value and add latency unit prefix
 * (i.e. bps, Kbps, Mbps, or Gbps)
 */
export const convertLatencyUnitPrefix = (latency: number): string =>
    Math.abs(latency) >= 1e9
        ? (latency / 1e9).toFixed(1).replace(/\.0$/, "") + "Gbps"
        : Math.abs(latency) >= 1e6
        ? (latency / 1e6).toFixed(1).replace(/\.0$/, "") + "Mbps"
        : Math.abs(latency) >= 1e3
        ? (latency / 1e3).toFixed(1).replace(/\.0$/, "") + "Kbps"
        : latency.toFixed(1) + "bps";

/**
 * Check whether the local browser permission is denied. If the browser
 * doesn't support the permission name, it will return true.
 */
export const isBrowserPermissionDenied = async (
    name: "camera" | "microphone"
): Promise<boolean> => {
    try {
        const result = await navigator.permissions.query({
            name: name as PermissionName,
        });
        return result.state === "denied";
    } catch {
        /*
         * Some browsers do not support querying some permissions (e.g. "camera", "microphone") and it will throw an error
         * e.g. `Failed to execute 'query' on 'Permissions': Failed to read the 'name' property from 'PermissionDescriptor'
         */
        return true;
    }
};

/**
 * Check whether the local browser permission is granted. If the browser
 * already granted the permission name, it will return true.
 */
export const isBrowserPermissionGranted = async (
    name: "camera" | "microphone"
): Promise<boolean> => {
    try {
        const result = await navigator.permissions.query({
            name: name as PermissionName,
        });
        return result.state === "granted";
    } catch {
        return false;
    }
};
