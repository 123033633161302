// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

// NOTE: Add the metric name on AWSErmineWebAppLoggingServiceCDK to create metric filters.
// There are 2 types of metric filters.
// 1. Publishing count/value metric. This is used for regular count and latency metrics.
// 2. Publishing HTTP status code metric. This is for capturing HTTP status code metrics.
export enum METRIC_NAME {
    COGNITO_CALL = "CognitoCallCount",
    COGNITO_FAILURE = "CognitoFailure",
    COGNITO_LOGIN_SUCCESS = "CognitoLoginSuccess",
    CLICK = "ClickCount",
    START_STREAMING = "StartStreaming",
    STREAMING_URL_CACHE_HIT = "StreamingURLCacheHit",
    CREATE_REMOTE_BROWSER = "CreateRemoteBrowser",
    SEND_CLIENT_MESSAGE = "SendClientMessage",
    REFRESH = "RefreshCount",
    HARDWARE_COMPATIBILITY_SUCCESS = "HardwareCompatibilitySuccess",
    HARDWARE_COMPATIBILITY_FAILURE = "HardwareCompatibilityFailure",
    DEVICE_BROWSER = "DeviceBrowser",
    DEVICE_OS = "DeviceOS",
    DEVICE_MODEL = "DeviceModel",
    DEVICE_TYPE = "DeviceType",
    DEVICE_TOUCHSCREEN = "DeviceTouchScreen",
    APPSTREAM_EMBED_SERVER_ERROR = "AppStreamEmbedServerError",
    OIDC_GET_COOKIE_SYNC_CONFIG = "OidcGetCookieSynchronizationConfiguration",
    SIGV4_GET_COOKIE_SYNC_CONFIG = "Sigv4GetCookieSynchronizationConfiguration",
    MISSING_COOKIE_SYNC_EXTENSION = "MissingCookieSynchronizationExtension",
    COOKIE_EXTENSION_RESPONSE = "CookieExtensionResponse",
    COOKIE_EXTENSION_RESPONSE_TIME = "CookieExtensionResponseTime",
    SESSION_FPS = "SessionFPS",
    SESSION_LATENCY = "SessionLatency",
}

export enum TOOLBAR_METRIC_NAME {
    TOOLBAR_VERSION = "ToolbarVersion",
    IME_TOGGLE_SUCCESS = "ImeToggleSuccess",
    IME_TOGGLE_ERROR = "ImeToggleError",
    IME_TOGGLE_DEFAULT_SUCCESS = "ImeToggleDefaultSuccess",
    IME_TOGGLE_DEFAULT_ERROR = "ImeToggleDefaultError",
    STREAMING_BEST_RESPONSIVE_SUCCESS = "StreamingBestResponsiveSuccess",
    STREAMING_BEST_RESPONSIVE_DEFAULT_SUCCESS = "StreamingBestResponsiveDefaultSuccess",
    STREAMING_BEST_RESPONSIVE_ERROR = "StreamingBestResponsiveError",
    STREAMING_BEST_QUALITY_SUCCESS = "StreamingBestQualitySuccess",
    STREAMING_BEST_QUALITY_ERROR = "StreamingBestQualityError",
    SET_COMMAND_AS_CONTROL_KEY_SUCCESS = "SetCommandAsControlKeySuccess",
    SET_COMMAND_AS_CONTROL_KEY_ERROR = "SetCommandAsControlKeyError",
    SET_COMMAND_AS_CONTROL_KEY_DEFAULT_SUCCESS = "SetCommandAsControlKeyDefaultSuccess",
    SET_COMMAND_AS_META_KEY_SUCCESS = "SetCommandAsMetaKeySuccess",
    SET_COMMAND_AS_META_KEY_DEFAULT_SUCCESS = "SetCommandAsMetaKeyDefaultSuccess",
    SET_COMMAND_AS_META_KEY_ERROR = "SetCommandAsMetaKeyError",
    TOOLBAR_MOVED = "ToolbarMoved",
    SESSION_STARTED_RESPONSE_TIME = "SessionStartedResponseTime",
    TOOLBAR_EXPANDED_DURATION_RATIO = "ToolbarExpandedDurationRatio",
    TOOLBAR_EXPANDED_DURATION = "ToolbarExpandedDuration",
    EXPAND_BUTTON_CLICK_COUNT = "ExpandButtonClickCount",
    EXPAND_HANDLE_CLICK_COUNT = "ExpandHandleClickCount",
    COLLAPSE_BUTTON_CLICK_COUNT = "CollapseButtonClickCount",
    COLLAPSE_HANDLE_CLICK_COUNT = "CollapseHandleClickCount",
    SET_DEFAULT_DARK_MODE = "SetDefaultDarkMode",
    SET_DARK_MODE = "SetDarkMode",
    SET_LIGHT_MODE = "SetLightMode",
    SET_FLOATING_TOOLBAR_MODE = "SetFloatingToolbarMode",
    SET_DOCKED_TOOLBAR_MODE = "SetDockedToolbarMode",
    CYCLE_TO_NEXT_WINDOW_SUCCESS = "CycleToNextWindowSuccess",
    CYCLE_TO_NEXT_WINDOW_ERROR = "CycleToNextWindowError",
    LAUNCH_CHROME_WINDOW_SUCCESS = "LaunchChromeWindowSuccess",
    LAUNCH_CHROME_WINDOW_ERROR = "LaunchChromeWindowError",
    DUAL_MONITOR_SUCCESS = "DualMonitorSuccess",
    DUAL_MONITOR_ERROR = "DualMonitorError",
    ENABLE_MICROPHONE_EXPANDED_VIEW_SUCCESS = "EnableMicrophoneExpandedViewSuccess",
    ENABLE_MICROPHONE_MINI_VIEW_SUCCESS = "EnableMicrophoneMiniViewSuccess",
    ENABLE_MICROPHONE_ERROR = "EnableMicrophoneError",
    DISABLE_MICROPHONE_EXPANDED_VIEW_SUCCESS = "DisableMicrophoneExpandedViewSuccess",
    DISABLE_MICROPHONE_MINI_VIEW_SUCCESS = "DisableMicrophoneMiniViewSuccess",
    DISABLE_MICROPHONE_ERROR = "DisableMicrophoneError",
    MICROPHONE_ENABLED_DURATION_RATIO = "MicrophoneEnabledDurationRatio",
    MICROPHONE_ENABLED_DURATION = "MicrophoneEnabledDuration",
    FLOATING_TOOLBAR_MODE_ENABLED_DURATION = "FloatingToolbarModeEnabledDuration",
    // wsw-toolbar-settings-cookie metrics
    SET_TOOLBAR_SETTINGS_COOKIE_SUCCESS = "SetToolbarSettingsCookieSuccess",
    SET_TOOLBAR_SETTINGS_COOKIE_ERROR = "SetToolbarSettingsCookieError",
    SET_VISUAL_MODE_IN_COOKIE_SUCCESS = "SetVisualModeInCookieSuccess",
    SET_VISUAL_MODE_IN_COOKIE_ERROR = "SetVisualModeInCookieError",
    SET_TOOLBAR_MODE_IN_COOKIE_SUCCESS = "SetToolbarModeInCookieSuccess",
    SET_TOOLBAR_MODE_IN_COOKIE_ERROR = "SetToolbarModeInCookieError",
    SET_STREAMING_MODE_IN_COOKIE_SUCCESS = "SetStreamingModeInCookieSuccess",
    SET_STREAMING_MODE_IN_COOKIE_ERROR = "SetStreamingModeInCookieError",
    SET_DISPLAY_RESOLUTION_IN_COOKIE_SUCCESS = "SetDisplayResolutionInCookieSuccess",
    SET_DISPLAY_RESOLUTION_IN_COOKIE_ERROR = "SetDisplayResolutionInCookieError",
    SET_OPTION_KEY_SETTING_IN_COOKIE_SUCCESS = "SetOptionKeySettingInCookieSuccess",
    SET_OPTION_KEY_SETTING_IN_COOKIE_ERROR = "SetOptionKeySettingInCookieError",
    SET_COMMAND_KEY_SETTING_IN_COOKIE_SUCCESS = "SetCommandKeySettingInCookieSuccess",
    SET_COMMAND_KEY_SETTING_IN_COOKIE_ERROR = "SetCommandKeySettingInCookieError",
    SET_FUNCTION_KEY_SETTING_IN_COOKIE_SUCCESS = "SetFunctionKeySettingInCookieSuccess",
    SET_FUNCTION_KEY_SETTING_IN_COOKIE_ERROR = "SetFunctionKeySettingInCookieError",
    SET_TOOLBAR_ICON_LABEL_SETTING_IN_COOKIE_SUCCESS = "SetToolbarIconLabelSettingInCookieSuccess",
    SET_TOOLBAR_ICON_LABEL_SETTING_IN_COOKIE_ERROR = "SetToolbarIconLabelSettingInCookieError",
    GET_TOOLBAR_SETTINGS_COOKIE_SUCCESS = "GetToolbarSettingsCookieSuccess",
    GET_TOOLBAR_SETTINGS_COOKIE_ERROR = "GetToolbarSettingsCookieError",
    GET_VISUAL_MODE_FROM_COOKIE_SUCCESS = "GetVisualModeFromCookieSuccess",
    GET_VISUAL_MODE_FROM_COOKIE_ERROR = "GetVisualModeFromCookieError",
    GET_TOOLBAR_MODE_FROM_COOKIE_SUCCESS = "GetToolbarModeFromCookieSuccess",
    GET_TOOLBAR_MODE_FROM_COOKIE_ERROR = "GetToolbarModeFromCookieError",
    GET_STREAMING_MODE_FROM_COOKIE_SUCCESS = "GetStreamingModeFromCookieSuccess",
    GET_STREAMING_MODE_FROM_COOKIE_ERROR = "GetStreamingModeFromCookieError",
    GET_DISPLAY_RESOLUTION_FROM_COOKIE_SUCCESS = "GetDisplayResolutionFromCookieSuccess",
    GET_DISPLAY_RESOLUTION_FROM_COOKIE_ERROR = "GetDisplayResolutionFromCookieError",
    GET_OPTION_KEY_SETTING_FROM_COOKIE_SUCCESS = "GetOptionKeySettingFromCookieSuccess",
    GET_OPTION_KEY_SETTING_FROM_COOKIE_ERROR = "GetOptionKeySettingFromCookieError",
    GET_COMMAND_KEY_SETTING_FROM_COOKIE_SUCCESS = "GetCommandKeySettingFromCookieSuccess",
    GET_COMMAND_KEY_SETTING_IN_COOKIE_ERROR = "GetCommandKeySettingFromCookieError",
    GET_FUNCTION_KEY_SETTING_FROM_COOKIE_SUCCESS = "GetFunctionKeySettingFromCookieSuccess",
    GET_FUNCTION_KEY_SETTING_FROM_COOKIE_ERROR = "GetFunctionKeySettingFromCookieError",
    GET_TOOLBAR_ICON_LABEL_SETTING_FROM_COOKIE_SUCCESS = "GetToolbarIconLabelSettingFromCookieSuccess",
    GET_TOOLBAR_ICON_LABEL_SETTING_FROM_COOKIE_ERROR = "GetToolbarIconLabelSettingFromCookieError",
    // deep link notifications
    DEEP_LINK_ACCESS_DENIED_ERROR = "DeepLinkAccessDeniedError",
    DEEP_LINK_INTERNAL_SERVER_ERROR = "DeepLinkInternalServerError",
    DEEP_LINK_LIMIT_EXCEEDED_ERROR = "DeepLinkLimitExceededError",
    DEEP_LINK_INVALID_URL_ERROR = "DeepLinkInvalidUrlError",
    // camera metrics
    ENABLE_WEBCAM_EXPANDED_VIEW_SUCCESS = "EnableWebcamExpandedViewSuccess",
    ENABLE_WEBCAM_MINI_VIEW_SUCCESS = "EnableWebcamMiniViewSuccess",
    ENABLE_WEBCAM_ERROR = "EnableWebcamError",
    DISABLE_WEBCAM_EXPANDED_VIEW_SUCCESS = "DisableWebcamExpandedViewSuccess",
    DISABLE_WEBCAM_MINI_VIEW_SUCCESS = "DisableWebcamMiniViewSuccess",
    DISABLE_WEBCAM_ERROR = "DisableWebcamError",
    WEBCAM_ENABLED_DURATION = "WebcamEnabledDuration",
    WEBCAM_DISABLED_DURATION = "WebcamDisabledDuration",
    // peripheral selector
    SELECT_MICROPHONE_DEVICE_SUCCESS = "SelectMicrophoneDeviceSuccess",
    SELECT_MICROPHONE_DEVICE_ERROR = "SelectMicrophoneDeviceError",
    SELECT_WEBCAM_DEVICE_SUCCESS = "SelectWebcamDeviceSuccess",
    SELECT_WEBCAM_DEVICE_ERROR = "SelectWebcamDeviceError",
    GET_CONNECTED_MICROPHONES_SUCCESS = "GetConnectedMicrophonesSuccess",
    GET_CONNECTED_MICROPHONES_ERROR = "GetConnectedMicrophonesError",
    GET_CONNECTED_WEBCAMS_SUCCESS = "GetConnectedWebcamsSuccess",
    GET_CONNECTED_WEBCAMS_ERROR = "GetConnectedWebcamsError",
    GET_BROWSER_PERMISSIONS_SUCCESS = "GetBrowserPermissionsSuccess",
    GET_BROWSER_PERMISSIONS_ERROR = "GetBrowserPermissionsError",
}
