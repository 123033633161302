// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import KatalLogger, { LoggerConfig } from "@amzn/katal-logger";
import { METRIC_NAME, TOOLBAR_METRIC_NAME } from "./metricName";
import {
    EmfMetric,
    createEmfFormattedMetric,
    EmfHttpStatusCodeMetric,
    createEmfHttpStatusCodeMetric,
} from "./emfUtils";

type ErmineConfig = LoggerConfig;

export class ErmineLogger extends KatalLogger {
    constructor(config?: ErmineConfig) {
        super({ ...config });
    }

    public logMessage(
        message: string,
        context?: Record<string, unknown>
    ): void {
        context = context || {};
        context.userSub = window.userSub;
        this.info(message, context);
    }

    public publishCounterMetric(
        metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
        count = 1
    ): void {
        const emfMetric: EmfMetric = {
            name: metricName,
            value: count,
            unit: "Count",
            dimensions: {},
        };
        this.info(metricName, createEmfFormattedMetric(emfMetric));
    }

    public publishMetricWithDimension(
        metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
        dimensionValue: string
    ): void {
        const emfMetric: EmfMetric = {
            name: metricName,
            value: 1,
            unit: "Count",
            dimensions: { dimension: dimensionValue },
        };
        this.info(metricName, createEmfFormattedMetric(emfMetric));
    }

    public publishNumericMetric(
        metricName: METRIC_NAME | TOOLBAR_METRIC_NAME,
        value: number
    ): void {
        const emfMetric: EmfMetric = {
            name: metricName,
            value: value,
            unit: "None",
            dimensions: {},
        };
        this.info(metricName, createEmfFormattedMetric(emfMetric));
    }

    public publishHttpStatusMetric(
        metricName: METRIC_NAME,
        httpStatus: number
    ): void {
        let emfMetricSuffix = "";

        if (httpStatus >= 200 && httpStatus < 300) {
            emfMetricSuffix = "200";
        } else if (httpStatus >= 400 && httpStatus < 500) {
            emfMetricSuffix = "4xx";
        } else if (httpStatus >= 500 && httpStatus < 600) {
            emfMetricSuffix = "5xx";
        } else {
            console.warn(
                `An unexpected httpStatusCode value was returned from ${metricName}`
            );
        }

        const emfMetric: EmfHttpStatusCodeMetric = {
            name: `${metricName}-${emfMetricSuffix}`,
            value: 1,
            unit: "Count",
            httpStatusCode: httpStatus,
            dimensions: {},
        };
        this.info(metricName, createEmfHttpStatusCodeMetric(emfMetric));
    }
}
