import { useEffect } from "react";
import { useSessionDataStore } from "./useSessionDataStore";
import { ToolbarMode } from "../constants/Toolbar";
import { shallow } from "zustand/shallow";

export const useFloatingToolbarModeTimer = (toolbarMode: ToolbarMode): void => {
    const {
        addFloatingToolbarEnabledDuration,
        setFloatingToolbarLastEnabledTime,
        sessionStartTime,
    } = useSessionDataStore(
        (store) => ({
            addFloatingToolbarEnabledDuration:
                store.addFloatingToolbarEnabledDuration,
            setFloatingToolbarLastEnabledTime:
                store.setFloatingToolbarLastEnabledTime,
            sessionStartTime: store.sessionStartTime,
        }),
        shallow
    );

    useEffect(() => {
        if (sessionStartTime !== undefined) {
            if (toolbarMode === ToolbarMode.Floating) {
                const floatingToolbarEnabledTimestamp = performance.now();
                setFloatingToolbarLastEnabledTime(
                    floatingToolbarEnabledTimestamp
                );
            } else {
                const floatingToolbarDisabledTimestamp = performance.now();
                const floatingToolbarLastEnabledTime = useSessionDataStore.getState()
                    .floatingToolbarLastEnabledTime;
                if (floatingToolbarLastEnabledTime !== undefined) {
                    addFloatingToolbarEnabledDuration(
                        floatingToolbarDisabledTimestamp -
                            floatingToolbarLastEnabledTime
                    );
                }
            }
        }
    }, [toolbarMode, sessionStartTime]);
};
