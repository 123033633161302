import { z } from "zod";
import crypto from "crypto";

/** Max Streaming URL Max size in bytes.
 * Appstream's spec describes spec:
 * > Length Constraints: Minimum length of 1.
 *
 * Streaming URL depends on the session context. Thus,
 * our URLs are consistently ~1500 bytes in size. Reserving
 * double for good measure.
 *
 * @todo i/ERMINE-XXXX Add monitoring on streaming URL size.
 */
export const STREAMING_URL_MAX_SIZE_BYTES = 3072;

/**
 * This is an ENUM defined in our model, it currently only has the value
 * `CHROME` but may support more values in future.
 */
export const BROWSER_TYPE_MAX_SIZE_BYTES = 64;

/**
 * StreamingURL caching schema.
 *
 * @note if we exceed our max streaming URL size, the streaming URL will
 * be rejected and hence the caching logic should switch off.
 */
export const StreamingURLCacheSchema = z.object({
    streamingURL: z.string().max(STREAMING_URL_MAX_SIZE_BYTES).optional(),
    browserType: z.string().max(BROWSER_TYPE_MAX_SIZE_BYTES).optional(),
});

/**
 * Streaming URL Cache object type derived from the schema
 */
export type StreamingURLCache = z.infer<typeof StreamingURLCacheSchema>;

// =============== SESSION COOKIE ============== //

/**
 * Schema for our Session State Cookie.
 * We will validate against this schema on ingestion and
 * invalidate the cookie if it does not have valid schema.
 *
 * If you ever need custom validation of fields:
 * https://github.com/colinhacks/zod/issues/37
 */
export const SessionStateCookieSchema = z.object({
    streamingURLCache: StreamingURLCacheSchema.optional(),
    hideExtensionModal: z.boolean().optional(),
});

/**
 * Derived type for the wsw-session-state cookie
 */
export type SessionStateCookie = z.infer<typeof SessionStateCookieSchema>;
