import { create } from "zustand";
import { ToolbarPreferenceTabId } from "../constants/Toolbar";

interface ToolbarPreferenceTabIdStore {
    activeId: ToolbarPreferenceTabId;
    setActiveId: (id: ToolbarPreferenceTabId) => void;
}

/**
 * Store for managing opened tab in the Preference toolbar item
 */
export const useToolbarPreferenceTabIdStore = create<
    ToolbarPreferenceTabIdStore
>((store) => ({
    activeId: ToolbarPreferenceTabId.GENERAL,
    setActiveId: (id) =>
        store((state) => ({
            ...state,
            activeId: id,
        })),
}));
