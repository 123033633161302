// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import React from "react";
import { getAuthType, isFeatureEnabled } from "../../../configurations";
import { AuthType } from "../../../enums";
import { AuthState, AuthStrategy } from "../../../types";
import { CognitoAuthStrategy } from "../cognito/CognitoAuthStrategy";
import { SSOAuthStrategy } from "../sso/SSOAuthStrategy";
import SignInPage from "../signIn/SignInPage";
import EUCSignInPage from "../signIn/EUCSignInPage";
import { getLegacyFlag } from "../../../utils/toolbarItemUtils";

interface Props {
    onSignIn: (authState: AuthState) => void;
    errorState?: Record<string, any>;
}

const Authentication = (props: Props): JSX.Element => {
    const { onSignIn, errorState } = props;
    const authType = getAuthType();
    const isUnificationEnabled = isFeatureEnabled("unification");

    let authStrategy: AuthStrategy;
    switch (authType) {
        case AuthType.STANDARD: {
            authStrategy = isUnificationEnabled
                ? new SSOAuthStrategy()
                : new CognitoAuthStrategy();
            break;
        }
        case AuthType.IAM_IDENTITY_CENTER: {
            authStrategy = new SSOAuthStrategy();
            break;
        }
    }

    return getLegacyFlag() ? (
        <SignInPage
            onSignIn={onSignIn}
            authStrategy={authStrategy}
            errorState={errorState}
        />
    ) : (
        <EUCSignInPage
            onSignIn={onSignIn}
            authStrategy={authStrategy}
            errorState={errorState}
        />
    );
};

export default Authentication;
