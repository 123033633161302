import React from "react";
import Badge from "@mui/material/Badge";
import Icon from "@cloudscape-design/components/icon";
import { useTranslation } from "react-i18next";

interface NotificationIconWithBadgeProps {
    badgeNumber: number;
}

export const NotificationIconWithBadge = ({
    badgeNumber,
}: NotificationIconWithBadgeProps): JSX.Element => {
    const { t } = useTranslation();
    let badgeAriaLabel: string;
    switch (badgeNumber) {
        case 0:
            badgeAriaLabel = "";
            break;
        case 1:
            badgeAriaLabel = t("notification.badge.arialabel.singular");
            break;
        default:
            badgeAriaLabel = t("notification.badge.ariaLabel.plural", {
                count: badgeNumber,
            });
    }
    return (
        <Badge
            badgeContent={badgeNumber}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            aria-label={badgeAriaLabel}
            sx={{
                "& .MuiBadge-badge": {
                    fontSize: "10px",
                    borderRadius: "50px",
                    minWidth: "8px",
                    maxHeight: "16px",
                    backgroundColor: "#90CAF9",
                    color: "#233540",
                    top: "7px",
                },
            }}
        >
            <Icon data-testid="notification-icon" name="notification" />
        </Badge>
    );
};
