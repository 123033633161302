// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React from "react";
import PropTypes from "prop-types";
import { Alert } from "@amzn/awsui-components-react";
import { ERROR_STATE } from "../../../constants";

interface Props {
    type: ERROR_STATE;
    header: string;
    message: string;
    refreshButtonText?: string;
    onRefreshButtonClick?: (event: CustomEvent) => void;
    dismissible?: boolean;
    onDismissErrorAlert: (event: CustomEvent) => void;
    visible?: boolean;
}

const ErrorAlert = (props: Props): JSX.Element => {
    const {
        type,
        header,
        message,
        refreshButtonText,
        onRefreshButtonClick,
        dismissible,
        onDismissErrorAlert,
        visible,
    } = props;

    return (
        <Alert
            id="errorAlert"
            type={type as Alert.Type}
            header={header}
            dismissible={dismissible ?? true}
            onDismiss={onDismissErrorAlert}
            buttonText={refreshButtonText}
            onButtonClick={onRefreshButtonClick}
            visible={visible ?? true}
        >
            <div className="awsui-util-d-ib">{message}</div>
        </Alert>
    );
};

ErrorAlert.propTypes = {
    type: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onDismissErrorAlert: PropTypes.func.isRequired,
};

export default ErrorAlert;
