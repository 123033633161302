import { getPortalConfig } from "../configurations";
import { useSessionDataStore } from "../hooks/useSessionDataStore";
import { getDeviceBrowser } from "../utils/userAgentUtils";

export interface EmfMetric {
    readonly name: string;
    // https://docs.aws.amazon.com/AmazonCloudWatch/latest/APIReference/API_MetricDatum.html
    readonly unit: string;
    readonly value: number;
    readonly dimensions?: { [key: string]: any };
}

export interface EmfHttpStatusCodeMetric extends EmfMetric {
    readonly httpStatusCode: number;
}

export const createEmfFormattedMetric = (metric: EmfMetric) => ({
    // This has to be added by the client side logger
    emfLog: {
        // This is the AWS EMF expected payload
        _aws: {
            Timestamp: Date.now(),
            CloudWatchMetrics: [
                {
                    Namespace: "AWSErmineWebApp-EMF",
                    Dimensions: [
                        metric.dimensions ? Object.keys(metric.dimensions) : [],
                    ],
                    Metrics: [
                        {
                            Name: metric.name,
                            Unit: metric.unit,
                            Value: metric.value,
                        },
                    ],
                },
            ],
        },
        ...metric.dimensions,
        [metric.name]: metric.value,
        PortalArn: getPortalConfig().portalArn,
        UserSub: window.userSub,
        Browser: getDeviceBrowser(),
        SessionId: useSessionDataStore.getState().sessionID,
    },
});

export const createEmfHttpStatusCodeMetric = (
    metric: EmfHttpStatusCodeMetric
) => ({
    emfLog: {
        _aws: {
            Timestamp: Date.now(),
            CloudWatchMetrics: [
                {
                    Namespace: "AWSErmineWebApp-EMF",
                    Dimensions: [
                        metric.dimensions ? Object.keys(metric.dimensions) : [],
                    ],
                    Metrics: [
                        {
                            Name: metric.name,
                            Unit: metric.unit,
                            Value: metric.value,
                            HttpStatusCode: metric.httpStatusCode,
                        },
                    ],
                },
            ],
        },
        ...metric.dimensions,
        [metric.name]: metric.value,
        PortalArn: getPortalConfig().portalArn,
        UserSub: window.userSub,
        Browser: getDeviceBrowser(),
        SessionId: useSessionDataStore.getState().sessionID,
    },
});
