import { useEffect, useState } from "react";
import { useMediaDeviceStore } from "./useMediaDeviceStore";
import { AppStreamSDK } from "../utils/AppStreamSDK";
import log, { TOOLBAR_METRIC_NAME } from "../logging";
import { Interfaces } from "@amzn/photon-portal-embed-sdk";
import IPhotonDevice = Interfaces.IPhotonDevice;

const useMicrophoneDevices = (appStreamEmbed: AppStreamSDK) => {
    const [microphoneDevices, setMicrophoneDevices] = useState<IPhotonDevice[]>(
        []
    );

    const setMicrophones = useMediaDeviceStore((store) => store.setMicrophones);
    const selectedMicrophone = useMediaDeviceStore(
        (store) => store.selectedMicrophone
    );
    const setSelectedMicrophone = useMediaDeviceStore(
        (store) => store.setSelectedMicrophone
    );

    const isMicrophonePermissionAllowed = useMediaDeviceStore(
        (state) => state.isMicrophonePermissionAllowed
    );

    const fetchMicrophoneDevices = async () => {
        try {
            const connectedMicrophones = await appStreamEmbed.getConnectedMicrophones();
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_CONNECTED_MICROPHONES_SUCCESS
            );
            setMicrophones(connectedMicrophones);
            setMicrophoneDevices(connectedMicrophones);
        } catch (error) {
            console.error(
                "Error getting connected microphones from AppStream:",
                error
            );
            log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.GET_CONNECTED_MICROPHONES_ERROR
            );
        }
    };

    useEffect(() => {
        fetchMicrophoneDevices();

        const handleDeviceChange = async () => {
            await fetchMicrophoneDevices();
        };

        navigator.mediaDevices.addEventListener(
            "devicechange",
            handleDeviceChange
        );

        return () => {
            navigator.mediaDevices.removeEventListener(
                "devicechange",
                handleDeviceChange
            );
        };
    }, [isMicrophonePermissionAllowed]);

    useEffect(() => {
        if (
            !selectedMicrophone &&
            microphoneDevices.length > 0 &&
            microphoneDevices[0].deviceId !== ""
        ) {
            setSelectedMicrophone(microphoneDevices[0]);
        }
    }, [microphoneDevices]);

    return microphoneDevices;
};

export default useMicrophoneDevices;
