import { cookieManagerInstance } from "../cookies";
import {
    CustomCookieIdentifier,
    DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS,
} from "../constants/ShortbreadConfig";
import { useTranslation } from "react-i18next";

/**
 * Get the Tutorial cookie and return a boolean.
 * If no cookie is found, return false.
 * For EU customer who doesn't have consent to functional cookie, return true to avoid tutorial showing up every time.
 */
export const getTutorialCookie = (): boolean => {
    try {
        const tutorialCookie = JSON.parse(
            cookieManagerInstance.getCookieStorage(
                CustomCookieIdentifier.TUTORIAL.name
            )
        ); // this will convert string to boolean if exists
        return tutorialCookie ?? false;
    } catch {
        return false;
    }
};

/**
 * Set the Tutorial cookie as true if tutorial is finished by user.
 */
export const setTutorialCookie = (isFinished: boolean) => {
    cookieManagerInstance.setCookieStorage(
        CustomCookieIdentifier.TUTORIAL.name,
        isFinished.toString(), // cookie value is string type
        DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS
    );
};

/**
 * Set up tutorial i18n strings
 */
export const TutorialI18nStrings = () => {
    const { t } = useTranslation();

    return {
        taskTitle: () => "",
        stepCounterText: (stepIndex: number, totalStepCount: number): string =>
            `${stepIndex + 1}/${totalStepCount}`,
        labelHotspot: (
            openState: boolean,
            stepIndex: number,
            totalStepCount: number
        ) =>
            openState
                ? t("toolbar.tutorial.labelHotspot.close", {
                      stepIndex: stepIndex + 1,
                      totalSteps: totalStepCount,
                  })
                : t("toolbar.tutorial.labelHotspot.open", {
                      stepIndex: stepIndex + 1,
                      totalSteps: totalStepCount,
                  }),
        nextButtonText: t("toolbar.tutorial.nextButtonText"),
        previousButtonText: t("toolbar.tutorial.previousButtonText"),
        finishButtonText: t("toolbar.tutorial.finishButtonText"),
        labelDismissAnnotation: t("toolbar.tutorial.labelDismissAnnotation"),
    };
};
