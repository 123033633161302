// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@mui/material";

interface Props {
    title: string;
    message: string;
    render: boolean;
}

const WarningAlert = (props: Props): JSX.Element => {
    const { title, message, render } = props;
    const [open, setOpen] = useState(render);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                id="warning-alert-dialog"
            >
                <DialogTitle id="warning-alert-dialog-title" variant="h5">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="warning-alert-dialog-description"
                        color="#5B5958"
                        variant="h6"
                    >
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions id="warning-alert-dialog-actions">
                    <Button
                        onClick={handleClose}
                        id="warning-alert-close-button"
                    >
                        <Typography variant="h6" color="#38E29D">
                            CLOSE
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

WarningAlert.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    render: PropTypes.bool.isRequired,
};

export default WarningAlert;
