import {
    Grid,
    LineChart,
    SpaceBetween,
    Spinner,
} from "@cloudscape-design/components";
import React, { useCallback } from "react";
import { useSessionMetricStore } from "../../../../hooks/useSessionMetricStore";
import { ValueWithLabel } from "../../ValueWithLabel";
import {
    convertLatencyUnitPrefix,
    convertToLastNcountSessionMetricData,
} from "../../../../utils/toolbarItemUtils";
import { MAX_METRIC_COUNT } from "../../../../constants/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import { CopyButton } from "../../../general/CopyButton";
import { useSessionDataStore } from "../../../../hooks/useSessionDataStore";

/**
 * Profile - Session performance tab content
 */
export const SessionPerformanceTabContent = (): JSX.Element => {
    const { t } = useTranslation();

    const latestSessionMetric = useSessionMetricStore(
        (store) => store.latestMetric
    );
    const traffics = useSessionMetricStore((store) => store.traffics);
    const sessionId = useSessionDataStore((store) => store.sessionID);

    const xAxisLabelFormatter = useCallback(
        (second: number) => {
            if (second === 0) {
                return t(
                    "toolbar.item.user.content.tab.sessionPerformance.xAxis.label.current"
                );
            }
            if (second === 1) {
                return t(
                    "toolbar.item.user.content.tab.sessionPerformance.xAxis.label.nSec.one"
                );
            }
            return t(
                "toolbar.item.user.content.tab.sessionPerformance.xAxis.label.nSec.plural",
                {
                    second,
                }
            );
        },
        [t]
    );

    return (
        <Grid
            gridDefinition={[
                { colspan: { default: 12, xxs: 3 } },
                { colspan: { default: 12, xxs: 9 } },
            ]}
        >
            <SpaceBetween size="m">
                <ValueWithLabel
                    label={t(
                        "toolbar.item.user.content.tab.sessionPerformance.frameRate.label"
                    )}
                >
                    {latestSessionMetric == null ? (
                        <Spinner data-testid={"loading-session-metric-fps"} />
                    ) : (
                        <>{latestSessionMetric.fps}fps</>
                    )}
                </ValueWithLabel>
                <ValueWithLabel
                    label={t(
                        "toolbar.item.user.content.tab.sessionPerformance.networkLatency.label"
                    )}
                >
                    {latestSessionMetric == null ? (
                        <Spinner
                            data-testid={"loading-session-metric-latency"}
                        />
                    ) : (
                        <>{latestSessionMetric.latency}ms</>
                    )}
                </ValueWithLabel>
                <ValueWithLabel
                    label={t(
                        "toolbar.item.user.content.tab.sessionPerformance.sessionId.label"
                    )}
                >
                    {sessionId == null ? (
                        <Spinner
                            data-testid={"loading-session-data-sessionId"}
                        />
                    ) : (
                        <>
                            <CopyButton
                                copyText={sessionId}
                                successPopoverText={
                                    <Trans
                                        i18nKey={
                                            "toolbar.item.user.content.tab.sessionPerformance.sessionId.popover.success"
                                        }
                                    />
                                }
                                errorPopoverText={
                                    <Trans
                                        i18nKey={
                                            "toolbar.item.user.content.tab.sessionPerformance.sessionId.popover.failed"
                                        }
                                    />
                                }
                                copyIconAriaLabel={t(
                                    "toolbar.item.user.content.tab.sessionPerformance.sessionId.copy.button.ariaLabel"
                                )}
                            />
                            <>{sessionId}</>
                        </>
                    )}
                </ValueWithLabel>
            </SpaceBetween>
            <LineChart
                ariaLabel={t(
                    "toolbar.item.user.content.tab.sessionPerformance.chart.ariaRole.description"
                )}
                series={[
                    {
                        title: t(
                            "toolbar.item.user.content.tab.sessionPerformance.usage.current.label"
                        ),
                        type: "line",
                        data: convertToLastNcountSessionMetricData(
                            traffics,
                            MAX_METRIC_COUNT
                        ),
                        valueFormatter: convertLatencyUnitPrefix,
                        color: "#7492E7",
                    },
                    {
                        title: t(
                            "toolbar.item.user.content.tab.sessionPerformance.usage.peak.label"
                        ),
                        type: "threshold",
                        y: latestSessionMetric?.averageTraffic ?? 0,
                        valueFormatter: convertLatencyUnitPrefix,
                        color: "#B44869",
                    },
                    {
                        title: t(
                            "toolbar.item.user.content.tab.sessionPerformance.usage.average.label"
                        ),
                        type: "threshold",
                        y: latestSessionMetric?.peakTraffic ?? 0,
                        valueFormatter: convertLatencyUnitPrefix,
                        color: "#53A296",
                    },
                ]}
                i18nStrings={{
                    legendAriaLabel: t(
                        "toolbar.item.user.content.tab.sessionPerformance.legend.ariaLabel"
                    ),
                    chartAriaRoleDescription: t(
                        "toolbar.item.user.content.tab.sessionPerformance.chart.ariaRole.description"
                    ),
                    yTickFormatter: convertLatencyUnitPrefix,
                    xTickFormatter: xAxisLabelFormatter,
                }}
                xDomain={[60, 0]}
                yDomain={[0, latestSessionMetric?.peakTraffic ?? 0]}
                height={150}
                xTitle={t(
                    "toolbar.item.user.content.tab.sessionPerformance.xTitle"
                )}
                yTitle={t(
                    "toolbar.item.user.content.tab.sessionPerformance.yTitle"
                )}
                loadingText={t(
                    "toolbar.item.user.content.tab.sessionPerformance.chart.loading"
                )}
                statusType={
                    latestSessionMetric && traffics.length > 0
                        ? undefined
                        : "loading"
                }
                hideFilter
            />
        </Grid>
    );
};
